import { Component, Vue, Watch } from "vue-property-decorator";
import { ToolbarItem } from "../models/ToolbarItem";
import { ValidationResult } from "vee-validate/dist/types/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  CustomerViewModel,
  CustomsOfficeViewModel,
  DataApi,
  CustomsOfficeCustomerViewModel,
  CustomsOfficeCustomerApi,
} from "../openapi";

const customsOfficeCustomerApi = new CustomsOfficeCustomerApi(undefined, "");
@Component({})
export default class CustomsOfficeCustomers extends Vue {
  dataApi: DataApi = new DataApi(undefined, "");

  headers = [
    { text: "Actions", value: "actions", sortable: false },
    { text: "Customs Office", align: "start", value: "customsOfficeId" },
    { text: "CustomerId", value: "customerId" },
    {
      text: "Freightcost Calculation Type",
      value: "freightcostCalculationType",
    },
    { text: "Base Freightcost", value: "baseFreightcost" },
    { text: "Freightcost Per Piece", value: "freightcostPerPiece" },
    {
      text: "Freightcost Per Piece After X Pieces",
      value: "freightcostPerPieceAfterXPieces",
    },
    { text: "Freightcost Per KG", value: "freightcostPerKg" },
    {
      text: "Freightcost Per Kg After X Kg",
      value: "freightcostPerKgAfterXKg",
    },
  ];
  isLoading = false;
  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [25, 50, 100, -1],
    disablePagination: false,
  };
  options: any = {
    page: 1,
    itemsPerPage: 100,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  items: CustomsOfficeCustomerViewModel[] = [];
  totalItems: number = 0;
  timeoutDelay: any = null;
  disableNewItemButton: Boolean = false;
  customers: CustomerViewModel[] = [];
  customsOffices: CustomsOfficeViewModel[] = [];
  freightCostCalculationTypes: { text: string; value: number }[] = [
    {
      text: "Based On Pieces",
      value: 0,
    },
    {
      text: "Based On Weight",
      value: 1,
    },
  ];

  async created() {
    var toolbarButtons: ToolbarItem[] = [
      {
        callback: () => this.refresh(),
        icon: "mdi-refresh",
        tooltipText: "Refresh overview",
      },
    ];
    this.$emit("PageInfoReceived", "Inbound freightcosts", toolbarButtons);

    var responses = await Promise.all([
      this.dataApi.getCustomers(true),
      this.dataApi.getCustomsOffices(),
    ]);
    var customerResponse = responses[0];
    if (customerResponse.status === 200) {
      this.customers = customerResponse.data;
    }

    var customsOffices = responses[1];
    if (customsOffices.status === 200) {
      this.customsOffices = customsOffices.data;
    }
  }

  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.disableNewItemButton = false;
      this.loadItems(
        newVal.page,
        newVal.itemsPerPage,
        this.getSort(newVal.sortDesc),
        this.getSortField(newVal.sortBy),
      );
    }, 250);
  }

  loadItems(
    page: number,
    itemsPerPage: number,
    orderByField: string,
    orderBy: string,
  ) {
    this.isLoading = true;
    this.footerOptions.disablePagination = true;
    customsOfficeCustomerApi
      .getCustomsOfficeCustomers(itemsPerPage, page, orderBy, orderByField)
      .then((result) => {
        this.items = result.data.items!;
        this.totalItems = result.data.totalItems;
      })
      .finally(() => {
        this.isLoading = false;
        this.footerOptions.disablePagination = false;
      });
  }

  async saveItem(item: CustomsOfficeCustomerViewModel) {
    var validationObserver = (<Vue[]>(
      this.$refs["observer-" + item.id]
    ))[0] as InstanceType<typeof ValidationObserver>;
    const promises: Promise<ValidationResult>[] = [];
    for (const key in this.$refs) {
      const split = key.split("-");
      if (!split.length || key === "observer-" + item.id) {
        continue;
      }

      const propName = split[0];
      const id = parseInt(split[1]);
      if (id != item.id) {
        continue;
      }

      const validationProvider = (<Vue[]>this.$refs[key])[0] as InstanceType<
        typeof ValidationProvider
      >;
      promises.push(
        validationProvider.validate(
          item[propName as keyof CustomsOfficeCustomerViewModel],
        ),
      );
    }

    validationObserver.validate().then(async (isValid) => {
      const validationResults = await Promise.all(promises);
      for (var validationResult of validationResults) {
        if (!validationResult.valid) {
          return;
        }
      }

      this.isLoading = true;
      const isNewItem = this.isNewItem(item);
      const postData = JSON.parse(JSON.stringify(item));

      customsOfficeCustomerApi
        .saveCustomsOfficeCustomer(postData as CustomsOfficeCustomerViewModel)
        .then((result) => {
          if (!result?.data) {
            return;
          }

          this.mapItem(item, result.data);
          if (isNewItem) {
            this.disableNewItemButton = false;
          }
        })
        .catch((error) => {
          this.$emit("errorOccured", [error.message]);
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  }

  addNewItem() {
    this.disableNewItemButton = true;
    this.items.unshift({
      id: 0,
      baseFreightCost: 0,
      customerId: 0,
      customsOfficeId: 0,
      freightCostCalculationType: 0,
      freightCostPerKg: 0,
      freightCostPerKgAfterXKg: 0,
      freightCostPerPiece: 0,
      freightCostPerPieceAfterXPieces: 0,
    } as CustomsOfficeCustomerViewModel);
    this.totalItems++;
  }

  deleteItem(item: any) {
    this.isLoading = true;
    const index = this.items.findIndex((cocu) => cocu.id === item.id);
    if (this.isNewItem(item)) {
      this.items.splice(index, 1);
      this.isLoading = false;
      this.disableNewItemButton = false;
      return;
    }

    customsOfficeCustomerApi
      .deleteCustomsOfficeCustomer(item.id)
      .then((c) => {
        this.items.splice(index, 1);
      })
      .catch(() => {})
      .finally(() => {
        const options = this.options;
        this.loadItems(
          options.page,
          options.itemsPerPage,
          this.getSort(options.sortDesc),
          this.getSortField(options.sortBy),
        );
      });
  }

  isNewItem(item: CustomsOfficeCustomerViewModel): Boolean {
    return item?.id === 0;
  }

  mapItem(oldItem: any, newItem: any) {
    for (const key in oldItem) {
      oldItem[key] = newItem[key];
    }
  }

  getSortField(sortFields: string[]) {
    return sortFields[0] ?? "";
  }

  getSort(sortDesc: Boolean[]) {
    let isDesc = sortDesc[0] ?? null;
    if (!(isDesc === false || isDesc === true)) {
      return "";
    }
    return isDesc ? "DESC" : "ASC";
  }

  refresh() {
    this.loadItems(
      this.options.page,
      this.options.itemsPerPage,
      this.getSort(this.options.sortDesc),
      this.getSortField(this.options.sortBy),
    );
    this.disableNewItemButton = false;
  }

  getOfficeName(item: CustomsOfficeCustomerViewModel) {
    var office = this.customsOffices.find(
      (co) => co.id === item.customsOfficeId,
    );
    if (!office) {
      return "";
    }

    return office.officeNumber;
  }

  getCustomerName(item: CustomsOfficeCustomerViewModel) {
    var customer = this.customers.find((c) => c.id === item.customerId);
    if (!customer) {
      return "";
    }

    return customer.shortName;
  }

  getFreightCalculationType(item: CustomsOfficeCustomerViewModel) {
    var calculationType = this.freightCostCalculationTypes.find(
      (f) => f.value === item.freightCostCalculationType,
    );
    if (!calculationType) {
      return "";
    }

    return calculationType.text;
  }
}
