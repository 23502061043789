import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue.html";
import CustomsFlowStepsMixin from "@/mixins/CustomsFlowStepsMixin";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue.html";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CustomsShipmentItemViewModel,
  CustomsT2DeclarionItem,
  CustomsTransitApi,
  CustomsShipmentDetailViewModel,
  CustomsExportApi,
} from "@/openapi";
import { UploadFileForm } from "./dialogs/UploadFileDialog";
import UploadFileDialog from "./dialogs/UploadFileDialog.vue.html";
import ShipmentPaperwork from "./ShipmentPaperwork.vue.html";
import CountriesMixin from "@/mixins/CountriesMixin";
import FilterParcels from "./FilterParcels.vue.html";

const customsTransitApi = new CustomsTransitApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");
@Component({
  components: {
    CustomsExportShipmentDetailViewNew,
    ShipmentStatusFlow,
    UploadFileDialog,
    ShipmentPaperwork,
    FilterParcels,
  },
})
export default class CustomsExportReadyForTransitDeclarations extends mixins(
  CustomsFlowStepsMixin,
  CountriesMixin,
) {
  @Prop({ default: [] })
  t2Declarations!: CustomsT2DeclarionItem[];

  isLoading = false;

  options: any = {
    page: 1,
    itemsPerPage: 5,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };

  filter: any = {
    step: null,
    shipmentStatusDescription: "",
    parcels: [],
    exportMrn: "",
    shipperCountry: null,
    consigneeCountry: null,
  };
  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
  };
  totalItems: Number = 0;
  expanded = [];

  startStep = 8;
  endStep = 12;

  statusFilter: number[] = [];
  shipmentStatusFilter = [
    "Hold",
    "Return",
    "Customs inspection",
    "Loading confirmed",
    "Waiting for customs release",
    "Customs EXIT released",
  ];

  items: CustomsShipmentItemViewModel[] = [];
  currentHandlingItem: CustomsShipmentItemViewModel | null = null;
  currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
  currentDeclarationItem: CustomsT2DeclarionItem | null = null;

  showDialogUploadFile = false;
  dialogUploadFileLoading = false;
  currentDeclarationItemFileUpload: CustomsT2DeclarionItem | null = null;

  declarationHeaders = [
    {
      text: "MRN",
      value: "transitMrn",
      align: "start",
      width: "10%",
      sortable: false,
    },
    {
      text: "Expire date",
      value: "expireDate",
      align: "start",
      width: "10%",
      sortable: false,
    },
    {
      text: "Dakosy Reference",
      value: "dakosyReference",
      align: "start",
      width: "10%",
      sortable: false,
    },
    {
      text: "Registration",
      value: "registration",
      align: "start",
      width: "10%",
      sortable: false,
    },
    {
      text: "Seal(s)",
      value: "seals",
      align: "start",
      width: "20%",
      sortable: false,
    },
    {
      text: "Upload/download response",
      value: "response",
      align: "start",
      width: "15%",
      sortable: false,
    },
    {
      text: "No response",
      value: "noResponse",
      align: "start",
      width: "15%",
      sortable: false,
    },
    {
      text: "CONSO",
      value: "consoDownload",
      align: "start",
      width: "10%",
      sortable: false,
    },
  ];

  shipmentHeaders = [
    {
      text: "Step",
      value: "exportFlowStatus",
      align: "center",
      sortable: false,
    },
    {
      text: "Shipment status",
      value: "shipmentStatusDescription",
      sortable: false,
    },
    {
      text: "HAWB/Parcel ID",
      value: "hawb",
      sortable: false,
      width: "15em",
    },
    {
      text: "MRN ",
      value: "exportMrn",
      sortable: false,
      width: "18em",
    },
    {
      text: "LTS",
      value: "lts",
      sortable: false,
    },
    {
      text: "Anzahl ",
      value: "pieces",
      sortable: false,
      align: "end",
    },
    {
      text: "Gewicht ",
      value: "grossWeight",
      sortable: false,
      align: "end",
    },
    { text: "EORI", value: "eoriNumber", sortable: false },
    {
      text: "Versender",
      value: "shipper",
      sortable: false,
      width: "15em",
    },
    {
      text: "Empfänger",
      value: "consignee",
      sortable: false,
      width: "15em",
    },
    {
      text: "Warenwert",
      value: "value",
      sortable: false,
      align: "end",
    },
    { text: "Währung", value: "valueCurrency", sortable: false },
    {
      text: "Warenbeschreibung / tarifnummer",
      value: "articles",
      sortable: false,
      width: "25em",
    },
  ];

  created() {
    this.statusFilter = getStatuses(false, false, false, false, true);
  }

  timeoutShipmentsDelay = 0;
  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutShipmentsDelay);
    this.timeoutShipmentsDelay = setTimeout(() => {
      this.loadShipments(this.currentDeclarationItem!.id!, this.options.page);
    }, 250);
  }

  @Watch("expanded")
  onExpandedChanged(
    newVal: CustomsShipmentItemViewModel[],
    oldVal: CustomsShipmentItemViewModel[],
  ) {
    if (newVal.length === 0) {
      this.currentHandlingItem = null;
      this.currentShipmentDetail = null;
    } else {
      this.currentHandlingItem = newVal[0];

      this.isLoading = true;
      this.currentShipmentDetail = null;
      customsExportApi
        .getCustomsShipmentDetail(this.currentHandlingItem.id!)
        .then((response) => (this.currentShipmentDetail = response.data))
        .catch((error) =>
          this.displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (this.isLoading = false));
    }
  }

  onExpandedDeclaration({
    item,
    value,
  }: {
    item: CustomsT2DeclarionItem;
    value: boolean;
  }) {
    if (!value) {
      this.currentDeclarationItem = null;
      this.currentShipmentDetail = null;
      this.expanded = [];
      this.items = [];
      this.totalItems = 0;
    } else {
      this.currentDeclarationItem = item;
      this.loadShipments(this.currentDeclarationItem!.id!, 1);
    }
  }

  async loadShipments(declarationId: number, page: number) {
    try {
      this.isLoading = true;
      const response = await customsTransitApi.getDeclarationShipments({
        declarationId,
        page,
        itemsPerPage: this.options.itemsPerPage,
        step: this.filter.step ?? undefined,
        shipmentStatusDescription:
          this.filter.shipmentStatusDescription ?? undefined,
        parcels: this.filter.parcels,
        exportMrn: this.filter.exportMrn ?? undefined,
        shipperCountry: this.filter.shipperCountry ?? undefined,
        consigneeCountry: this.filter.consigneeCountry ?? undefined,
      });

      this.items = response.data.shipments!;
      this.totalItems = response.data.totalAmount!;
    } catch {
      this.displayError(
        "Something went wrong while retrieving the shipments of the selected T2 declaration.",
      );
    }

    this.isLoading = false;
  }

  onFilterSelected() {
    this.loadShipments(this.currentDeclarationItem!.id!, this.options.page);
  }

  displayError(errorMessage: string) {
    this.$emit("errorOccured", errorMessage);
  }

  onConsoDownload(item: CustomsT2DeclarionItem) {
    alert("Still WIP :)");
  }

  async setNoResponse(item: CustomsT2DeclarionItem) {
    this.isLoading = true;
    try {
      await customsTransitApi.setDeclarationNoResponse(item.id);
      this.$emit("reloadDeclarations");
    } catch {
      this.displayError(
        'Something went wrong during the "no response" action.',
      );
    }
    this.isLoading = false;
  }

  uploadResponse(item: CustomsT2DeclarionItem) {
    this.currentDeclarationItemFileUpload = item;
    this.showDialogUploadFile = true;
  }

  async onFileUploaded({ files }: UploadFileForm) {
    this.dialogUploadFileLoading = true;

    try {
      await customsTransitApi.uploadDeclarationResponse(
        this.currentDeclarationItemFileUpload?.id,
        files![0],
      );
      this.$emit("reloadDeclarations");
      this.showDialogUploadFile = false;
    } catch {
      this.displayError(
        "Something went wrong while uploading the declaration response.",
      );
    }

    this.dialogUploadFileLoading = false;
  }

  onFileUploadCanceled() {
    this.currentDeclarationItemFileUpload = null;
  }
}
