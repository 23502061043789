import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import GroupArticlesDialog from "../components/dialogs/GroupArticlesDialog.vue";
import {
  ArticleViewModel,
  CurrencyViewModel,
  ArticleTypeViewModel,
  UnitOfMeasurementViewModel,
  GroupArticlesViewModel,
} from "@/openapi";

class GroupFunctionItem {
  name: string = "";
  items: ArticleViewModel[] = [];
}

@Component({
  components: {
    GroupArticlesDialog,
  },
})
export default class Articles extends Vue {
  alignTop = false;
  @Prop({})
  articles!: ArticleViewModel[];

  @Prop({})
  valuesCurrency!: CurrencyViewModel[];

  @Prop({ default: false })
  errorWithArticles!: boolean;

  @Prop({})
  exchangeRateCurrencies!: CurrencyViewModel[];

  @Prop({})
  frachtkostenPercentage!: number | undefined;

  @Prop({})
  getRoundedTo2DigitsValue!: Function;

  @Prop({})
  updateArticlePrices!: boolean;

  @Prop({})
  articleTypes!: ArticleTypeViewModel[];

  @Prop({})
  unitsOfMeasurement!: UnitOfMeasurementViewModel[];

  observer!: InstanceType<typeof ValidationObserver>;

  groupArticleDialog = false;
  groupArticleVm: GroupArticlesViewModel = {} as GroupArticlesViewModel;
  showWtnNumberIsNot11Characters = false;

  @Watch("articles", { deep: true })
  onArticlesChanged(newVal: ArticleViewModel[], oldVal: ArticleViewModel[]) {
    this.updateArticleCalculations(newVal);
    this.$emit("update-zollwert");
    this.$emit("update:group-by", "rcTarif");
  }

  @Watch("updateArticlePrices", { immediate: true })
  onUpdateArticlePricesChanged() {
    this.updateArticleCalculations(this.articles);
    this.$emit("update:updateArticlePrices", false);
  }

  @Watch("groupArticleVm.artikel")
  onGroupArticleChanged(newVal: string, oldVal: string) {
    const article = this.articleTypes.find((a) => a.name === newVal);
    if (article !== undefined && article !== null) {
      this.groupArticleVm.wtnNumber = article.wtnNumber;
      this.groupArticleVm.description = article.description;
      this.groupArticleVm.additionalCode = article.additionalCode;
      this.groupArticleVm.massEinheit = article.unitOfMeasurement;
      this.groupArticleVm.zollMengeMassEinheit =
        article.zollMengeUnitOfMeasurement;
    }
  }

  mounted() {
    this.observer = this.$refs.observer as InstanceType<
      typeof ValidationObserver
    >;
  }

  get groupableArticles() {
    return this.articles.filter((a) => a.canBeGrouped);
  }

  updateArticleCalculations(articles: ArticleViewModel[]) {
    for (const article of this.articles) {
      const articleCurrency = this.exchangeRateCurrencies.find(
        (cer) => cer.name === article.rechnungswahrung,
      );
      const freightCostsCurrency = this.exchangeRateCurrencies.find(
        (cer) => cer.name === article.frachtkostenWahrung,
      );
      const artikelPreis =
        articleCurrency != undefined
          ? article.rechnungspreis! / articleCurrency.exchangeRate!
          : 0;
      const zollWertInEuros =
        freightCostsCurrency != undefined
          ? article.frachtkosten! / freightCostsCurrency.exchangeRate!
          : 0;
      const zollwert =
        artikelPreis +
          ((this.frachtkostenPercentage !== undefined
            ? this.frachtkostenPercentage
            : 0) /
            100) *
            zollWertInEuros ?? 0;

      article.artikelPreis = artikelPreis;
      article.zollwert = zollwert;
      article.statWert = Math.round(article.zollwert);
    }

    this.$emit("update-zollwert");
  }

  groupFunction(
    items: ArticleViewModel[],
    groupBy: string[],
    groupDesc: boolean[],
  ) {
    const groupedArray = items.reduce((arr, item) => {
      const notGroupedArticlesArr = arr.findIndex(
        (item) => item.name === "dontGroup",
      );
      let nonGroupedItems: ArticleViewModel[] = [];
      if (notGroupedArticlesArr !== -1) {
        nonGroupedItems = arr[notGroupedArticlesArr].items;
      } else {
        const newArrayItem = {
          name: "dontGroup",
          items: nonGroupedItems,
        } as GroupFunctionItem;
        arr.push(newArrayItem);
      }

      if (!item.groupArticle) {
        nonGroupedItems.push(item);
        return arr;
      }

      if (!item.rcTarif) {
        const individualItemGroup = {
          items: [item],
        } as GroupFunctionItem;
        arr.push(individualItemGroup);
        return arr;
      }

      const existingArrIndex = arr.findIndex(
        (arrItem, index) => arrItem.name === item.rcTarif,
      );
      if (existingArrIndex !== -1) {
        arr[existingArrIndex].items.push(item);
      } else {
        const newArrayItem = {
          name: item.rcTarif,
          items: [item],
        } as GroupFunctionItem;
        arr.push(newArrayItem);
      }

      return arr;
    }, [] as GroupFunctionItem[]);

    return groupedArray;
  }

  groupSelectedArticles() {
    this.observer.validateWithInfo().then((x) => {
      if (!x.isValid) {
        return;
      }

      if (
        this.groupArticleVm.wtnNumber !== null &&
        this.groupArticleVm.wtnNumber !== undefined &&
        this.groupArticleVm.wtnNumber.length !== 11
      ) {
        this.showWtnNumberIsNot11Characters = true;
        return;
      }

      this.groupArticleDialog = false;
      this.$emit("group-articles", this.groupArticleVm);
    });
  }

  groupSelectedArticlesDialog(articles: ArticleViewModel[]) {
    const articlesToGroup = articles.filter(
      (a) => a.groupArticle && a.canBeGrouped,
    );
    const articleIds = articlesToGroup.map((item) => item.id);
    const firstArticle = articlesToGroup[0];

    this.groupArticleDialog = true;
    this.groupArticleVm = {
      articleIds: articleIds,
      customsHandlingId: this.articles[0].customsHandlingId,
      rcTarif: firstArticle.rcTarif,
      scTarif: firstArticle.scTarif,
      additionalCode: firstArticle.additionalCode,
      artikel: firstArticle.artikel,
      description: firstArticle.description,
      frachtkostenWahrung: firstArticle.frachtkostenWahrung,
      frachtkosten: this.getRoundedTo2DigitsValue(
        articlesToGroup.reduce(
          (currentValue, currentItem) =>
            (currentValue += parseFloat(
              (currentItem.frachtkosten ?? 0).toString(),
            )),
          0,
        ),
      ),
      rechnungswahrung: firstArticle.rechnungswahrung,
      wtnNumber: firstArticle.wtnNumber,
      artikelPreis: this.getRoundedTo2DigitsValue(
        articlesToGroup.reduce(
          (currentValue, currentItem) =>
            (currentValue += parseFloat(
              (currentItem.artikelPreis ?? 0).toString(),
            )),
          0,
        ),
      ),
      eigenmasse: this.getRoundedTo2DigitsValue(
        this.getMinimumValue(
          articlesToGroup.reduce(
            (currentValue, currentItem) =>
              (currentValue += parseFloat(
                (currentItem.eigenmasse ?? 0).toString(),
              )),
            0,
          ),
        ),
      ),
      freightcostPercentage: this.frachtkostenPercentage,
      rechnungspreis: this.getRoundedTo2DigitsValue(
        articlesToGroup.reduce(
          (currentValue, currentItem) =>
            (currentValue += parseFloat(
              (currentItem.rechnungspreis ?? 0).toString(),
            )),
          0,
        ),
      ),
      rohmasse: this.getRoundedTo2DigitsValue(
        this.getMinimumValue(
          articlesToGroup.reduce(
            (currentValue, currentItem) =>
              (currentValue += parseFloat(
                (currentItem.rohmasse ?? 0).toString(),
              )),
            0,
          ),
        ),
      ),
      statWert: this.getRoundedTo2DigitsValue(
        articlesToGroup.reduce(
          (currentValue, currentItem) =>
            (currentValue += parseFloat(
              (currentItem.statWert ?? 0).toString(),
            )),
          0,
        ),
      ),
      zollwert: this.getRoundedTo2DigitsValue(
        articlesToGroup.reduce(
          (currentValue, currentItem) =>
            (currentValue += parseFloat(
              (currentItem.zollwert ?? 0).toString(),
            )),
          0,
        ),
      ),
      massEinheit: firstArticle.massEinheit,
      zollMengeMassEinheit: firstArticle.zollMengeMassEinheit,
      statMenge: this.getRoundedTo2DigitsValue(
        articlesToGroup.reduce(
          (currentValue, currentItem) =>
            (currentValue += parseFloat(
              (currentItem.statMenge ?? 0).toString(),
            )),
          0,
        ),
      ),
      zollMenge: this.getRoundedTo2DigitsValue(
        articlesToGroup.reduce(
          (currentValue, currentItem) =>
            (currentValue += parseFloat(
              (currentItem.zollMenge ?? 0).toString(),
            )),
          0,
        ),
      ),
    } as GroupArticlesViewModel;
  }

  tab = null;
  articleHeaders = [
    { text: "Group?", value: "groupArticle", sortable: false },
    { text: "", value: "googleLinks", sortable: false, width: "115px" },
    { text: "Warenbeschreibung", value: "description", width: "40%" },
    { text: "RC Tarif", value: "rcTarif" },
    { text: "SC Tarif", value: "scTarif" },
    { text: "Eigenmasse", value: "eigenmasse" },
    { text: "Rohmasse", value: "rohmasse" },
    { text: "Rechnungspreis", value: "rechnungspreis" },
    { text: "Rechnungswahrung", value: "rechnungswahrung" },
  ];

  articleHeadersFullOverview = [
    {
      text: "Article",
      value: "artikel",
    },
    { text: "WTN", value: "wtnNumber" },
    { text: "Zusatzcode", value: "additionalCode" },
    { text: "Warenbeschreibung", value: "description" },
    { text: "RcTarif", value: "rcTarif" },
    { text: "ScTarif", value: "scTarif" },
    { text: "Eigenmasse", value: "eigenmasse" },
    { text: "Rohmasse", value: "rohmasse" },
    { text: "Rechnungspreis", value: "rechnungspreis" },
    { text: "Rechnungswahrung", value: "rechnungswahrung" },
    { text: "Artikelpreis", value: "artikelPreis" },
    { text: "Frachtkosten", value: "frachtkosten" },
    { text: "Frachtkostenwahrung", value: "frachtkostenWahrung" },
    { text: "Zollwert", value: "zollwert" },
    { text: "Stat. Wert", value: "statWert" },
    { text: "Stat. Menge", value: "statMenge" },
    { text: "Maßeinheit", value: "massEinheit" },
    { text: "Zollmenge", value: "zollMenge" },
  ];

  getArticleTypeText(item: ArticleTypeViewModel) {
    return `${item.name} (${item.wtnNumber})`;
  }

  getMassEinheitText(item: UnitOfMeasurementViewModel) {
    return `${item.code} - ${item.description}`;
  }

  setArticleFieldsBasedOnArticleType(item: ArticleViewModel) {
    const articleType = this.articleTypes.find((a) => a.name === item.artikel);
    if (articleType !== undefined && articleType !== null) {
      item.wtnNumber = articleType.wtnNumber;
      item.description = articleType.description;
      item.additionalCode = articleType.additionalCode;
      item.massEinheit = articleType.unitOfMeasurement;
      item.zollMengeMassEinheit = articleType.zollMengeUnitOfMeasurement;
    }
  }

  getMinimumValue(value: number) {
    return value > 0.1 ? value : 0.1;
  }
}
