import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  CustomsExportApi,
  ReturnReasonFirstViewModel,
  ReturnReasonSecondViewModel,
} from "@/openapi";

const customsExportApi = new CustomsExportApi(undefined, "");

import { Actions } from "@/config";

export interface ConfirmShipmentReasonForm {
  shipmentReason?: string;
  shipmentReasonFirst?: number | null;
  shipmentReasonSecond?: number | null;
}

@Component({})
export default class ConfirmShipmentReasonDialog extends Vue {
  @Prop({ default: false })
  value!: boolean;

  @Prop({ default: false })
  isLoading!: boolean;

  @Prop({ default: "" })
  title!: string;

  @Prop({
    validator: (value: string) =>
      [
        Actions.HOLD.toString(),
        Actions.RETURN,
        Actions.CUSTOMS_INSPECTION,
      ].includes(value),
  })
  action!: string;

  @Prop({ default: "Confirm" })
  confirmButtonText!: string;

  @Prop({ default: "Close" })
  closeButtonText!: string;

  rules = {
    required: (value: string) => (value && !!value.toString()) || "Required",
  };
  valid = true;

  returnAction = Actions.RETURN;

  form = this.setDefaultValuesForm();

  isRetrievingReturnReasons = false;

  returnReasonsFirst: ReturnReasonFirstViewModel[] = [];
  returnReasonsSecond: ReturnReasonSecondViewModel[] = [];
  returnReasonsBeschau: string[] = [
    "Customs inspection documents",
    "Customs inspection parcel",
  ];

  get displayTitle() {
    return !this.title ? this.action : this.title;
  }

  @Watch("value")
  onValueChanged(newValue: boolean, oldVal: boolean) {
    if (newValue == false) {
      this.form = this.setDefaultValuesForm();
      (
        this.$refs.form as Vue & { resetValidation: () => boolean }
      ).resetValidation();
      this.returnReasonsSecond = [];
    }
  }

  created() {
    if (this.action == Actions.RETURN) {
      this.getReturnReasons();
    }
  }

  displayError(errorMessage: string) {
    this.$emit("errorOccured", errorMessage);
  }

  returnReasonsChange() {
    var reasonFirst = this.returnReasonsFirst.find(
      (x) => x.id == this.form.shipmentReasonFirst,
    );
    if (reasonFirst == null) {
      this.returnReasonsSecond = [];
      return;
    }

    this.returnReasonsSecond = reasonFirst.returnReasonsSecond!;
    if (this.returnReasonsSecond.length == 1) {
      this.form.shipmentReasonSecond = this.returnReasonsSecond[0].id;
    }
  }

  async getReturnReasons() {
    this.returnReasonsFirst = [];
    this.isRetrievingReturnReasons = true;
    try {
      var response = await customsExportApi.getReturnReasons();
      this.returnReasonsFirst = response.data;
    } catch {
      this.displayError(
        "Something went wrong while retrieving the return reasons.",
      );
    }
    this.isRetrievingReturnReasons = false;
  }

  setDefaultValuesForm(): ConfirmShipmentReasonForm {
    return {
      shipmentReason: "",
      shipmentReasonFirst: null,
      shipmentReasonSecond: null,
    };
  }
}
