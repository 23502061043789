<template>
  <Fragment>
    <v-card>
      <v-card-text>
        <div class="container container--fluid">
          <div class="row">
            <div class="col col-12">
              <h1>Offices</h1>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                fixed-header
                dense
              >
                <template #item.showInvoiceInformationOnScannerApp="{ item }">
                  <v-icon
                    :color="
                      item.showInvoiceInformationOnScannerApp
                        ? 'success'
                        : 'error'
                    "
                    >{{
                      item.showInvoiceInformationOnScannerApp
                        ? "mdi-check"
                        : "mdi-close"
                    }}</v-icon
                  >
                </template>
                <template #item.showInCustoms="{ item }">
                  <v-icon :color="item.showInCustoms ? 'success' : 'error'">{{
                    item.showInCustoms ? "mdi-check" : "mdi-close"
                  }}</v-icon>
                </template>
                <template #item.dontAllowCustomsClearedScan="{ item }">
                  <v-icon
                    :color="
                      item.dontAllowCustomsClearedScan ? 'success' : 'error'
                    "
                    >{{
                      item.dontAllowCustomsClearedScan
                        ? "mdi-check"
                        : "mdi-close"
                    }}</v-icon
                  >
                </template>
                <template #item.actions="{ item }"
                  ><v-btn icon @click="editOffice(item)">
                    <v-icon>mdi-pencil</v-icon></v-btn
                  >
                  <v-btn icon @click="setOfficeToDelete(item)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
                <template #footer.prepend>
                  <v-btn @click="editOffice()">
                    <v-icon>mdi-plus</v-icon> ADD
                  </v-btn>
                </template>
              </v-data-table>
            </div>
          </div>
          <OfficeDialog
            :open.sync="openDialog"
            :detail.sync="dialogModel"
            :loading.sync="saving"
            @save="saveOffice"
          ></OfficeDialog>
          <ConfirmDialog
            v-model="confirmDelete"
            :is-loading="saving"
            title="Confirm delete"
            @confirm="deleteOffice"
          >
            Are you sure you want to delete "{{ officeNameToDelete }}"?
          </ConfirmDialog>
        </div>
      </v-card-text></v-card
    >
  </Fragment>
</template>
<script setup lang="ts">
import OfficeDialog from "./dialogs/OfficeDialog.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue.html";
import { computed, onMounted, ref } from "vue";
import { DataTableHeader } from "vuetify";
import { CustomsOfficesApi, CustomsOfficeModel } from "@/openapi";
const api = new CustomsOfficesApi(undefined, "");
const headers = ref<DataTableHeader[]>([
  {
    value: "customsOfficeNumber",
    text: "Customs Office number",
  },
  {
    value: "shortNumber",
    text: "Short number",
  },
  {
    value: "name",
    text: "Name",
  },
  {
    value: "showInvoiceInformationOnScannerApp",
    text: "Show invoice information on scanner app",
  },
  {
    value: "dontAllowCustomsClearedScan",
    text: "Show clearance/inspection information on scanner app",
  },
  {
    value: "actions",
    text: "",
  },
]);
const loading = ref(false);
const items = ref<CustomsOfficeModel[]>([]);
const openDialog = ref(false);
const saving = ref(false);
const dialogModel = ref<CustomsOfficeModel>();
const confirmDelete = ref(false);
const officeToDelete = ref(0);
const officeNameToDelete = ref("");
const defaultDialogModel = {
  id: null,
  customsOfficeNumber: null,
  shortNumber: 0,
  name: null,
  showInvoiceInformationOnScannerApp: false,
  dontAllowCustomsClearedScan: false,
} as CustomsOfficeModel;
const editOffice = (office?: CustomsOfficeModel) => {
  dialogModel.value = office ? { ...office } : { ...defaultDialogModel };
  openDialog.value = true;
};
const getOffices = async () => {
  loading.value = true;
  const response = await api.getOffices();
  items.value = response.data;
  loading.value = false;
};
const saveOffice = async (office: CustomsOfficeModel) => {
  saving.value = true;
  var response = await api.saveOffice(office);
  saving.value = false;
  openDialog.value = false;
  getOffices();
};
const setOfficeToDelete = (office?: CustomsOfficeModel) => {
  if (office?.id) {
    officeNameToDelete.value = office?.name ?? "";
    officeToDelete.value = office?.id!;
    confirmDelete.value = true;
  } else {
    officeNameToDelete.value = "";
    officeToDelete.value = 0;
    confirmDelete.value = false;
  }
};
const deleteOffice = async () => {
  saving.value = true;
  var response = await api.deleteOffice(officeToDelete.value);
  setOfficeToDelete(0);
  saving.value = false;
  openDialog.value = false;
  getOffices();
};
onMounted(() => {
  getOffices();
});
</script>
<style lang="scss"></style>
