import { Component, Vue, Watch } from "vue-property-decorator";
import { ToolbarItem } from "../models/ToolbarItem";
import { ReminderEmailViewModel, PaymentReminderEmailsApi } from "@/openapi";

const paymentReminderEmailsApi = new PaymentReminderEmailsApi(undefined, "");
@Component({})
export default class PaymentReminderEmails extends Vue {
  headers = [
    { text: "Reminder number", value: "reminderNumber" },
    { text: "Reminder subject English", value: "reminderSubjectEnglish" },
    { text: "Reminder subject German", value: "reminderSubjectGerman" },
  ];

  isLoading = false;
  saveSuccessful = false;
  expanded = [];
  items: ReminderEmailViewModel[] = [];
  currentItem: ReminderEmailViewModel | null = null;

  @Watch("expanded")
  onExpandedChanged(
    newVal: ReminderEmailViewModel[],
    oldVal: ReminderEmailViewModel[],
  ) {
    if (newVal.length === 0) {
      this.currentItem = null;
    } else {
      var reminderEmailId = newVal[0].id;
      this.isLoading = true;
      paymentReminderEmailsApi
        .getReminderEmailById(reminderEmailId)
        .then((response) => {
          this.currentItem = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    }
  }

  created() {
    var toolbarButtons: ToolbarItem[] = [
      {
        callback: () => this.refresh(),
        icon: "mdi-refresh",
        tooltipText: "Refresh overview",
      },
    ];

    this.$emit("PageInfoReceived", "Payment Reminder Emails", toolbarButtons);
    this.loadData();
  }

  refresh() {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;

    paymentReminderEmailsApi
      .getReminderEmails()
      .then((response) => {
        this.items = response.data;
        this.isLoading = false;
      })
      .catch((errors) => {
        this.isLoading = false;
      });
  }

  async save(item: ReminderEmailViewModel) {
    paymentReminderEmailsApi
      .saveReminderEmail(this.currentItem!)
      .then((result) => {
        this.currentItem = result.data;
        this.saveSuccessful = true;
      })
      .catch((error) => {
        this.saveSuccessful = false;
      });
  }
}
