import { Component, Prop, Vue } from "vue-property-decorator";
import UploadPaperworkDialog from "./dialogs/UploadPaperworkDialog.vue.html";
import { UploadPaperworkFormConfirmed } from "./dialogs/UploadPaperworkDialog";
import { CustomsPaperworkHandlingApi } from "@/openapi";
import { emitError } from "@/event-bus";
import axios from "axios";

import { serialize } from "object-to-formdata";

const paperworkHandlingApi = new CustomsPaperworkHandlingApi(undefined, "");

@Component({
  components: {
    UploadPaperworkDialog,
  },
})
export default class UploadPaperworkButton extends Vue {
  loading = false;
  showDialog = false;

  showSnackbar = false;
  snackbarTimeout = 4000;

  @Prop({ required: true })
  shipmentId!: number;

  @Prop({ default: "" })
  parcelNumber!: string;

  toggleDialog() {
    this.showDialog = !this.showDialog;
  }

  async onFilesSelected(eventData: UploadPaperworkFormConfirmed) {
    this.loading = true;
    try {
      const formData = serialize(
        {
          shipmentId: this.shipmentId,
          paperworkGroupings: eventData.paperworkGroupings.map((grouping) => ({
            paperworkCategory: grouping.category,
            documents: grouping.files,
          })),
        },
        {
          indices: true,
          dotsForObjectNotation: true,
          noFilesWithArrayNotation: true,
        },
      );

      await axios.post(
        "/api/CustomsPaperworkHandling/SavePaperworkDocumentsForShipment",
        formData,
      );

      this.showSnackbar = true;
      this.showDialog = false;
    } catch {
      emitError("Something went wrong while uploading the documents");
    }

    this.loading = false;
  }
}
