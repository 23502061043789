<template>
  <v-dialog v-model="openDialog" width="660px">
    <ValidationObserver slim v-slot="{ invalid }">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Document type </v-card-title
        ><v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :autofocus="true"
                    v-model="detail.name"
                    label="Name"
                  ></v-text-field
                ></ValidationProvider>
              </v-col> </v-row
            ><v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="detail.showInCustoms"
                  :hide-details="true"
                  label="Attachment can be selected for customs invoice"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="detail.showInCourierInvoices"
                  label="Attachment can be selected for courier invoice"
                  :hide-details="true"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="detail.showInCustomsDocuments"
                  :hide-details="true"
                  label="Show in Customs"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="detail.showInCourierInvoiceDocuments"
                  label="Show in courier invoices"
                  :hide-details="true"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialog" text> Close </v-btn>
          <v-btn
            color="success"
            @click="save"
            :loading="loading"
            :disabled="invalid || loading"
            text
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { DocumentTypeModel } from "@/openapi";

interface IProps {
  detail: DocumentTypeModel;
  open: boolean;
  loading: boolean;
}
const props = defineProps<IProps>();
const emit = defineEmits(["update:open", "save"]);

const openDialog = computed({
  get() {
    return props.open;
  },
  set(value: boolean) {
    emit("update:open", value);
  },
});

const closeDialog = () => {
  openDialog.value = false;
};

const save = () => {
  emit("save", props.detail);
};
</script>
