<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <validation-observer v-slot="{ invalid }">
        <v-card-title class="text-h5 grey lighten-2">Pre-Alert</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-radio-group v-model="alertType" row>
              <v-radio label="Private" value="private"></v-radio>
              <v-radio label="Company" value="company"></v-radio>
            </v-radio-group>
            <v-radio-group v-model="language" row>
              <v-radio
                :label="languageMapping[ReportingApiLanguage.NUMBER_0]"
                :value="ReportingApiLanguage.NUMBER_0"
              ></v-radio>
              <v-radio
                :label="languageMapping[ReportingApiLanguage.NUMBER_1]"
                :value="ReportingApiLanguage.NUMBER_1"
              ></v-radio>
            </v-radio-group>
            <v-select
              v-model="emailTemplate"
              :items="emailTemplates"
              item-text="name"
              item-value="id"
              label="Email Template"
              :loading="isLoadingEmailTemplates"
              :rules="emailTemplateRules"
            ></v-select>
            <v-select
              v-model="emailDocuments"
              :items="formattedDocuments"
              item-value="id"
              item-text="displayText"
              label="Documents"
              multiple
              chips
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :disabled="invalid"
            :loading="isLoadingPreview"
            @click="preview"
            >Preview</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog">Close</v-btn>
          <v-btn
            color="success"
            :disabled="!emailTemplate || disabled"
            :loading="isLoadingSend"
            @click="send"
            >Send</v-btn
          >
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { defineProps, defineEmits } from "vue";
import { emitError, emitSuccess } from "@/event-bus";
import {
  EmailTemplateApi,
  EmailTemplateSimple,
  ShipmentDetailDocumentsModel,
  ShipmentDetailApi,
  ShipmentApi,
  ReportingApiLanguage,
} from "@/openapi";

const props = defineProps<{
  disabled: boolean;
  modelValue: boolean;
  shipmentId: number;
  documents?: ShipmentDetailDocumentsModel[] | null;
}>();
const emit = defineEmits(["update:modelValue"]);

const dialog = ref(props.modelValue);
const valid = ref(false);
const alertType = ref("private");
const language = ref(ReportingApiLanguage.NUMBER_0);
const emailTemplate = ref(0);
const emailTemplates = ref<EmailTemplateSimple[]>([]);
const documents = ref<ShipmentDetailDocumentsModel[]>(props.documents ?? []);

const emailDocuments = ref<number[]>([]);
const api = new EmailTemplateApi(undefined, "");
const prealert = new ShipmentDetailApi(undefined, "");
const createPdf = new ShipmentApi(undefined, "");
const emailTemplateRules = [(v: unknown) => !!v || "Required"];
const isLoadingSend = ref(false);
const isLoadingPreview = ref(false);
const isLoadingEmailTemplates = ref(false);

const formattedDocuments = computed(() =>
  documents.value.map((doc) => ({
    ...doc,
    displayText: `${doc.typeName} - ${doc.fileName}`,
  })),
);
const languageMapping = {
  [ReportingApiLanguage.NUMBER_0]: "English",
  [ReportingApiLanguage.NUMBER_1]: "Deutsch",
};

watch(
  () => props.modelValue,
  (newVal) => {
    dialog.value = newVal;
    if (newVal) {
      isLoadingEmailTemplates.value = true;
      api
        .getEmailTemplates(6, false)
        .then((response) => {
          emailTemplates.value = response.data.emailTemplates ?? [];
          if (emailTemplates.value.length > 0) {
            emailTemplate.value = emailTemplates.value[0].id;
          }
        })
        .catch((error) => {
          emitError("Error fetching email templates");
        })
        .finally(() => {
          isLoadingEmailTemplates.value = false;
        });
    }
  },
);

watch(dialog, (newVal) => {
  emit("update:modelValue", newVal);
});

const preview = async () => {
  try {
    isLoadingPreview.value = true;
    const response = await createPdf.createPdfReportForPreAlert(
      props.shipmentId,
      alertType.value == "company",
      language.value,
      { responseType: "blob" },
    );
    const blob = new Blob([response.data], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
  } catch (error) {
    emitError("Error previewing pre-alert");
  } finally {
    isLoadingPreview.value = false;
  }
};

const send = async () => {
  try {
    isLoadingSend.value = true;
    const response = await prealert.sendPreAlert(
      emailTemplate.value,
      props.shipmentId,
      emailDocuments.value,
    );
    if (response.data === true) {
      closeDialog();
    }
    emitSuccess("Pre-alert sent successfully");
  } catch (error) {
    emitError("Error sending pre-alert");
  } finally {
    isLoadingSend.value = false;
  }
};

const closeDialog = () => {
  dialog.value = false;
  emailDocuments.value = [];
};
</script>
