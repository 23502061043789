import { Component, Vue, Prop } from "vue-property-decorator";
import {
  DataApi,
  CustomsDiscrepanciesApi,
  CustomsExportApi,
  ConsoFileCountry,
  ReturnReasonFirstViewModel,
  ReturnReasonSecondViewModel,
} from "../openapi";
import { emitError } from "../event-bus";
import { ValidationObserver } from "vee-validate";

import { useDataStore } from "@/stores/data-store";

const dataApi = new DataApi(undefined, "");
const discrepanciesApi = new CustomsDiscrepanciesApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");

interface DiscrepancyReturnForm {
  customer: number;
  hawb: string | null;
  shipmentReasonFirst: number;
  shipmentReasonSecond: number | null;
  parcelsText: string | null;
  consoFileCountry: number | null;
}

@Component({})
export default class CustomsDiscrepanciesReturn extends Vue {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  dataStore = useDataStore();

  returnReasonsFirst: ReturnReasonFirstViewModel[] = [];
  returnReasonsSecond: ReturnReasonSecondViewModel[] = [];

  form: DiscrepancyReturnForm = this.initializeForm();
  loading = false;
  showSnackbar = false;

  async created() {
    await Promise.all([
      this.getReturnReasons(),
      this.dataStore.fetchGeneralCustomers(),
    ]);
  }

  initializeForm() {
    return {} as DiscrepancyReturnForm;
  }

  async getReturnReasons() {
    const response = await customsExportApi.getReturnReasons();
    this.returnReasonsFirst = response.data;
  }

  returnReasonsChange() {
    this.form.shipmentReasonSecond = null;
    var reasonFirst = this.returnReasonsFirst.find(
      (x) => x.id == this.form.shipmentReasonFirst,
    );
    if (reasonFirst == null) {
      this.returnReasonsSecond = [];
      return;
    }

    this.returnReasonsSecond = reasonFirst.returnReasonsSecond!;
    if (this.returnReasonsSecond.length == 1) {
      this.form.shipmentReasonSecond = this.returnReasonsSecond[0].id;
    }
  }

  async onSubmit() {
    this.loading = true;
    try {
      await discrepanciesApi.returnShipmentWithoutData({
        customerId: this.form.customer,
        hawb: this.form.hawb,
        returnReasonFirstId: this.form.shipmentReasonFirst,
        returnReasonSecondId: this.form.shipmentReasonSecond,
        parcelNumbers: this.parcels,
        consoFileCountry: this.form.consoFileCountry as ConsoFileCountry,
      });
      this.form = this.initializeForm();
      this.$refs.observer.reset();
      this.showSnackbar = true;
    } catch {
      emitError("Failed to create a return shipment.");
    }

    this.loading = false;
  }

  get parcels() {
    const trimmedParcelsText = this.form.parcelsText?.trim();
    if (!trimmedParcelsText) {
      return [];
    }

    const parcels = trimmedParcelsText.split("\n").filter((p) => !!p);
    return parcels;
  }

  get hasSelectableSecondReturnReason() {
    return this.returnReasonsSecond?.length;
  }
}
