import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue.html";
import AddMrnDialog from "./dialogs/AddMrnDialog.vue.html";
import CustomsFlowStepsMixin from "@/mixins/CustomsFlowStepsMixin";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue.html";
import ConfirmShipmentReasonDialog from "./dialogs/ConfirmShipmentReasonDialog.vue.html";
import { getStatuses } from "@/helpers/statusHelper";
import UploadFileDialog from "./dialogs/UploadFileDialog.vue.html";
import {
  CustomsManifestApi,
  CustomsManifestItemViewModel,
  CustomsShipmentItemViewModel,
  ManifestStatusUpdate,
  CustomsShipmentDetailViewModel,
  CustomsExportApi,
} from "@/openapi";
import { UploadFileForm } from "./dialogs/UploadFileDialog";
import ConfirmDialog from "./dialogs/ConfirmDialog.vue.html";
import ShipmentPaperwork from "./ShipmentPaperwork.vue.html";
import CountriesMixin from "@/mixins/CountriesMixin";
import ToolbarMixin from "@/mixins/ToolbarMixin";
import FilterParcels from "./FilterParcels.vue.html";

import ReturnShipmentButton from "./buttons/ReturnShipmentButton.vue.html";
import CustomsInspectionButton from "./buttons/CustomsInspectionButton.vue.html";
import HoldShipmentButton from "./buttons/HoldShipmentButton.vue.html";

enum UploadResponseAction {
  Temp = 1,
  Final = 2,
}

const customsManifestApi = new CustomsManifestApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");

@Component({
  components: {
    CustomsExportShipmentDetailViewNew,
    AddMrnDialog,
    ShipmentStatusFlow,
    ConfirmShipmentReasonDialog,
    UploadFileDialog,
    ConfirmDialog,
    ShipmentPaperwork,
    FilterParcels,
    ReturnShipmentButton,
    CustomsInspectionButton,
    HoldShipmentButton,
  },
})
export default class CustomsExportShipmentsLow extends mixins(
  CustomsFlowStepsMixin,
  CountriesMixin,
  ToolbarMixin,
) {
  @Prop({ default: "" })
  color!: string;

  isLoading = false;
  options: any = {
    page: 1,
    itemsPerPage: 5,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };
  shipmentOptions: any = {
    page: 1,
    itemsPerPage: 5,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };

  manifestFilter: any = {
    manifestName: "",
    showAllManifests: false,
  };

  filter: any = {
    step: null,
    shipmentStatusDescription: "",
    parcels: [],
    exportMrn: "",
    shipperCountry: null,
    consigneeCountry: null,
  };
  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
  };
  totalItems: Number = 0;
  totalShipmentItems: Number = 0;
  expanded = [];

  statusFilter: number[] = [];
  shipmentStatusFilter = [
    "Hold",
    "Return",
    "Customs inspection",
    "Loading confirmed",
    "Waiting for customs release",
    "Customs EXIT released",
  ];

  items: CustomsManifestItemViewModel[] = [];
  currentManifestItem: CustomsManifestItemViewModel | null = null;
  currentHandlingItem: CustomsShipmentItemViewModel | null = null;
  currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
  customsHandlingItems: CustomsShipmentItemViewModel[] = [];
  selectedHandlingItems: CustomsShipmentItemViewModel[] = [];

  showDialogAddMrn = false;
  dialogAddMrnLoading = false;

  showDialogUploadFile = false;
  dialogUploadFileLoading = false;
  currentManifestItemFileUpload: CustomsManifestItemViewModel | null = null;
  currentUploadResponseAction: UploadResponseAction | null = null;

  showConfirmAcceptanceFromCustomsDialog = false;
  dialogConfirmAcceptanceFromCustomsLoading = false;
  confirmAcceptanceFromCustomsItem: CustomsManifestItemViewModel | null = null;

  headers = [
    {
      text: "Manifest-No.",
      value: "manifestNumber",
      align: "start",
      sortable: false,
    },
    {
      text: "Number of shipments",
      value: "numberOfShipments",
      align: "start",
      sortable: false,
    },
    {
      text: "Status",
      value: "shipmentStatus",
      align: "start",
      sortable: false,
    },
    {
      text: "Manifest flow",
      value: "confirmFlow",
      align: "left",
      width: "10%",
      sortable: false,
    },
    {
      text: "Download temp manifest",
      value: "tempManifest",
      align: "left",
      width: "10%",
      sortable: false,
    },
    {
      text: "Upload/download temp manifest response",
      value: "tempManifestResponse",
      align: "left",
      width: "10%",
      sortable: false,
    },
    {
      text: "Temp manifest no response",
      value: "tempManifestNoResponse",
      align: "left",
      width: "10%",
      sortable: false,
    },
    {
      text: "Download final manifest",
      value: "finalManifest",
      align: "left",
      width: "10%",
      sortable: false,
    },
    {
      text: "Upload/download final manifest response",
      value: "finalManifestResponse",
      align: "left",
      width: "10%",
      sortable: false,
    },
    {
      text: "Final manifest no response",
      value: "finalManifestNoResponse",
      align: "left",
      width: "10%",
      sortable: false,
    },
  ];

  customsHandlingItemsHeaders = [
    {
      text: "Step",
      value: "exportFlowStatus",
      align: "center",
    },
    {
      text: "Shipment status",
      value: "shipmentStatusDescription",
    },
    {
      text: "HAWB/Parcel ID",
      value: "hawb",
      sortable: false,
      width: "15em",
    },
    {
      text: "MRN ",
      value: "exportMrn",
      width: "18em",
    },
    {
      text: "Anzahl ",
      value: "pieces",
      sortable: false,
      align: "end",
    },
    {
      text: "Gewicht ",
      value: "grossWeight",
      sortable: false,
      align: "end",
    },
    { text: "EORI", value: "eori", sortable: false },
    {
      text: "Versender",
      value: "shipper",
      sortable: false,
      width: "15em",
    },
    {
      text: "Empfänger",
      value: "consignee",
      sortable: false,
      width: "15em",
    },
    {
      text: "Warenwert",
      value: "value",
      sortable: false,
      align: "end",
    },
    { text: "Währung", value: "valueCurrency", sortable: false },
    {
      text: "Warenbeschreibung / tarifnummer",
      value: "articles",
      sortable: false,
      width: "25em",
    },
  ];
  rules = {
    required: (value: string) => (value && !!value.toString()) || "Required",
    mrnnumber: (value: string) => {
      const pattern = /^[0-9]{2}[A-Z]{2}[a-zA-Z0-9]{14}$/;
      return pattern.test(value) || "Invalid MRN number";
    },
    email: (value: string) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail";
    },
  };

  created() {
    this.statusFilter = getStatuses();

    this.addRefreshToolbarOption(this.reloadManifests);
  }

  async loadManifests(page: number) {
    this.isLoading = true;
    try {
      var response = await customsManifestApi.getManifests(
        page,
        this.options.itemsPerPage,
        this.manifestFilter.manifestName ?? "",
        this.manifestFilter.showAllManifests,
      );
      this.items = response.data.items ?? [];
      this.totalItems = response.data.totalAmount ?? 0;
    } catch {
      this.displayError("Something went wrong while retrieving the manifests");
    }
    this.isLoading = false;
  }

  async reloadManifests() {
    this.options.page == 1
      ? await this.loadManifests(this.options.page)
      : (this.options.page = 1);
  }

  async loadShipmentItems(manifestId: number, page: number) {
    this.isLoading = true;
    try {
      const response = await customsManifestApi.getShipments({
        manifestId,
        page,
        itemsPerPage: this.shipmentOptions.itemsPerPage,
        step: this.filter.step ?? undefined,
        shipmentStatusDescription:
          this.filter.shipmentStatusDescription ?? undefined,
        parcels: this.filter.parcels,
        exportMrn: this.filter.exportMrn ?? undefined,
        shipperCountry: this.filter.shipperCountry ?? undefined,
        consigneeCountry: this.filter.consigneeCountry ?? undefined,
      });
      this.customsHandlingItems = response.data.shipments ?? [];
      this.totalShipmentItems = response.data.totalAmount ?? 0;
      this.currentManifestItem!.numberOfShipments = response.data.totalAmount;
      this.shipmentOptions.page = page;
    } catch (error) {
      this.displayError(
        "Something went wrong while retrieving the corresponding shipments.",
      );
    }
    this.isLoading = false;
  }

  timeoutDelay = 0;
  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.loadManifests(newVal.page);
    }, 250);
  }

  timeoutShipmentsDelay = 0;
  @Watch("shipmentOptions")
  onShipmentOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutShipmentsDelay);
    this.timeoutShipmentsDelay = setTimeout(() => {
      this.loadShipmentItems(
        this.currentManifestItem!.id!,
        this.shipmentOptions.page,
      );
    }, 250);
  }

  @Watch("expanded")
  onExpandedChanged(
    newVal: CustomsShipmentItemViewModel[],
    oldVal: CustomsShipmentItemViewModel[],
  ) {
    if (newVal.length === 0) {
      this.currentHandlingItem = null;
      this.currentShipmentDetail = null;
    } else {
      this.currentHandlingItem = newVal[0];

      this.isLoading = true;
      this.currentShipmentDetail = null;
      customsExportApi
        .getCustomsShipmentDetail(this.currentHandlingItem!.id!)
        .then((response) => (this.currentShipmentDetail = response.data))
        .catch((error) =>
          this.displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (this.isLoading = false));
    }
  }

  onExpandedItem({
    item,
    value,
  }: {
    item: CustomsManifestItemViewModel;
    value: boolean;
  }) {
    if (value) {
      this.currentManifestItem = item;
      this.loadShipmentItems(item!.id!, this.shipmentOptions.page);
    } else {
      this.currentManifestItem = null;
      this.customsHandlingItems = [];
    }
  }

  openDialogShipmentAddMrn(item: CustomsShipmentItemViewModel) {
    this.currentHandlingItem = item;
    this.showDialogAddMrn = true;
  }

  filterSearchEntered() {
    this.loadShipmentItems(
      this.currentManifestItem!.id!,
      this.shipmentOptions.page,
    );
  }

  closeAllDialogs() {
    this.showDialogAddMrn = false;
  }

  async onConfirmFlowChange(item: CustomsManifestItemViewModel) {
    if (item.status === 2) {
      // Force the user to confirm the last status change
      this.showConfirmAcceptanceFromCustomsDialog = true;
      this.confirmAcceptanceFromCustomsItem = item;
      return;
    }
    await this.changeManifestStatus(item);
  }

  async changeManifestStatus(item: CustomsManifestItemViewModel) {
    try {
      this.isLoading = true;
      await customsManifestApi.setManifestStatus({
        manifestId: item.id,
        manifestStatus: item.status! + 1,
      } as ManifestStatusUpdate);

      item.status!++;
      if (this.currentManifestItem?.id === item.id) {
        this.customsHandlingItems = [];
        await this.loadShipmentItems(item.id!, 1);
      }
    } catch {
      this.displayError(
        "Something went wrong while changing the status of the manifest.",
      );
    }
    this.isLoading = false;
  }

  getConfirmFlowButtonName(status: number) {
    switch (status) {
      case 0:
        return "CREATE MANIFEST";
      case 1:
        return "CONFIRM MANIFEST IS SENT TO CUSTOMS";
      case 2:
      case 3:
        return "CONFIRM ACCEPTANCE FROM CUSTOMS";
      default:
        return "ERROR";
    }
  }

  displayError(errorMessage: string) {
    this.$emit("errorOccured", errorMessage);
  }

  get isMrnEditable() {
    return (
      this.currentManifestItem != null && this.currentManifestItem.status! < 2
    );
  }

  async onMrnEntered({ mrn }: { mrn: string }) {
    this.dialogAddMrnLoading = true;
    try {
      await customsManifestApi.manifestSetMrn({
        mrn: mrn,
        shipmentId: this.currentHandlingItem!.id,
      });

      await this.loadShipmentItems(
        this.currentManifestItem!.id!,
        this.options.page,
      );
    } catch {
      this.displayError(
        "Something went wrong while setting the MRN of the shipment",
      );
    }

    this.dialogAddMrnLoading = false;
    this.showDialogAddMrn = false;
  }

  onManifestFilterEntered() {
    this.reloadManifests();
  }

  async setNoResponse(
    item: CustomsManifestItemViewModel,
    uploadResponseAction: UploadResponseAction,
  ) {
    this.isLoading = true;
    try {
      if (uploadResponseAction == UploadResponseAction.Temp) {
        await customsManifestApi.setTempManifestNoResponse(item.id);
        await this.loadManifests(this.options.page);
      } else if (uploadResponseAction == UploadResponseAction.Final) {
        await customsManifestApi.setFinalManifestNoResponse(item.id);
        await this.loadManifests(this.options.page);
      } else {
        this.displayError(
          "Something went wrong while uploading the manifest response.",
        );
        return;
      }
    } catch {
      this.displayError(
        "Something went wrong while uploading the manifest response.",
      );
    }
    this.isLoading = false;
  }

  uploadResponse(
    item: CustomsManifestItemViewModel,
    uploadResponseAction: UploadResponseAction,
  ) {
    this.currentManifestItemFileUpload = item;
    this.currentUploadResponseAction = uploadResponseAction;
    this.showDialogUploadFile = true;
  }

  async onFileUploaded({ files }: UploadFileForm) {
    this.dialogUploadFileLoading = true;

    try {
      if (this.currentUploadResponseAction == UploadResponseAction.Temp) {
        await customsManifestApi.uploadTempManifestResponse(
          this.currentManifestItemFileUpload?.id,
          files![0],
        );
        await this.loadManifests(this.options.page);
        this.showDialogUploadFile = false;
      } else if (
        this.currentUploadResponseAction == UploadResponseAction.Final
      ) {
        await customsManifestApi.uploadFinalManifestResponse(
          this.currentManifestItemFileUpload?.id,
          files![0],
        );
        await this.loadManifests(this.options.page);
        this.showDialogUploadFile = false;
      } else {
        this.displayError(
          "Something went wrong while uploading the manifest response.",
        );
      }
    } catch {
      this.displayError(
        "Something went wrong while uploading the manifest response.",
      );
    }

    this.dialogUploadFileLoading = false;
  }

  onFileUploadCanceled() {
    this.currentManifestItemFileUpload = null;
    this.currentUploadResponseAction = null;
  }

  async onConfirmAcceptanceFromCustoms() {
    this.dialogConfirmAcceptanceFromCustomsLoading = true;
    await this.changeManifestStatus(this.confirmAcceptanceFromCustomsItem!);
    this.dialogConfirmAcceptanceFromCustomsLoading = false;
    this.confirmAcceptanceFromCustomsItem = null;
    this.showConfirmAcceptanceFromCustomsDialog = false;
  }

  onShipmentStatusChanged({ success }: any) {
    if (success) {
      this.selectedHandlingItems = [];
      this.loadShipmentItems(this.currentManifestItem!.id!, this.options.page);
    }
  }
}
