<template>
  <v-dialog v-model="openDialog" width="660px">
    <ValidationObserver slim v-slot="{ invalid }">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Office </v-card-title
        ><v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <ValidationProvider
                  name="Customs Office number"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :autofocus="true"
                    v-model="detail.customsOfficeNumber"
                    label="Customs Office number"
                  ></v-text-field
                ></ValidationProvider> </v-col
              ><v-col>
                <ValidationProvider
                  name="Short number"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="detail.shortNumber"
                    type="number"
                    label="Short number"
                  ></v-text-field
                ></ValidationProvider> </v-col
              ><v-col>
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="detail.name"
                    label="Name"
                  ></v-text-field
                ></ValidationProvider>
              </v-col> </v-row
            ><v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="detail.showInvoiceInformationOnScannerApp"
                  :hide-details="true"
                  label="Show invoice information on scanner app"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="detail.dontAllowCustomsClearedScan"
                  label="Show clearance/inspection information on scanner app"
                  :hide-details="true"
                  class="mt-0"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="closeDialog" text> Close </v-btn>
          <v-btn
            color="success"
            @click="save"
            :loading="loading"
            :disabled="invalid || loading"
            text
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { CustomsOfficeModel } from "@/openapi";
interface IProps {
  detail: CustomsOfficeModel;
  open: boolean;
  loading: boolean;
}
const props = defineProps<IProps>();
const emit = defineEmits(["update:open", "save"]);
const openDialog = computed({
  get() {
    return props.open;
  },
  set(value: boolean) {
    emit("update:open", value);
  },
});
const closeDialog = () => {
  openDialog.value = false;
};
const save = () => {
  emit("save", props.detail);
};
</script>
