import { Component, Vue, Watch } from "vue-property-decorator";
import { ClaimViewModel, CustomsClaimApi } from "@/openapi";
import { ToolbarItem } from "../models/ToolbarItem";
import ClaimDetailView from "../components/ClaimDetailView.vue.html";
import moment from "moment";

const CcustomsClaimApi = new CustomsClaimApi(undefined, "");
@Component({
  components: {
    ClaimDetailView,
  },
})
export default class Claims extends Vue {
  showClaimDialog: Boolean = false;
  openClaimId: number = 0;
  isLoading: Boolean = false;
  items: ClaimViewModel[] = [];
  currentClaim: ClaimViewModel | null = null;

  expanded: ClaimViewModel[] = [];

  claimTypes: string[] = ["A", "B", "C"];

  headers = [
    { text: "Date", value: "claimDateOpened" },
    { text: "Category", value: "claimCategory" },
    { text: "Invoice number", value: "invoiceNumber" },
    { text: "Name", value: "invoiceName" },
  ];

  @Watch("expanded")
  onExpandedChanged(newVal: ClaimViewModel[], oldVal: ClaimViewModel[]) {
    if (newVal.length === 0) {
      this.currentClaim = null;
    } else {
      let claimId = newVal[0].claimId;
      this.isLoading = true;

      CcustomsClaimApi.getClaimById(claimId)
        .then((response) => {
          this.currentClaim = response.data;
        })
        .catch((error) => {
          this.$emit("errorOccured", [error.message]);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  created() {
    let toolbarButtons: ToolbarItem[] = [
      {
        callback: () => this.refresh(),
        icon: "mdi-refresh",
        tooltipText: "Refresh overview",
      },
    ];

    this.$emit("PageInfoReceived", "Open Claims", toolbarButtons);
    this.loadData();
  }

  refresh() {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;

    CcustomsClaimApi.listClaims()
      .then((response) => {
        this.items = response.data;
        this.isLoading = false;
      })
      .catch((errors) => {
        this.isLoading = false;
      });
  }

  openClaimDialog(id: number) {
    this.showClaimDialog = true;
    this.openClaimId = id;
  }

  closeClaimDialog() {
    this.showClaimDialog = false;
    this.openClaimId = 0;
  }

  proceedClaim() {
    //TODO SOVC-311: (mvl) - No information yet what this should do
  }

  formatDateTime(dateTime: Date) {
    if (dateTime === null || dateTime === undefined) {
      return "";
    }

    return moment(dateTime).format("DD-MM-YYYY");
  }
}
