<template>
  <v-container fluid fill-height>
    <v-row class="d-flex flex-grow-1">
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="isLoading"
          :footer-props="footerOptions"
          @update:options="onUpdateOptions"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small @click="saveItem(item)">mdi-content-save</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:header.actions="{ header }">
            <v-btn
              icon
              color="primary"
              @click="addNewItem"
              :disabled="disableNewItemButton"
            >
              <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.exportVollstaendigeAusfuhrAnmeldung="{ item }">
            <EditableField
              v-slot="{ removeEditable }"
              :id="'exportVollstaendigeAusfuhrAnmeldung-' + item.id"
            >
              <ValidationProvider
                name="Export Vollständige Ausfuhr Anmeldung"
                :vid="'exportVollstaendigeAusfuhrAnmeldung-' + item.id"
                rules="required"
                v-slot="{ errors }"
              >
                <v-checkbox
                  v-model="item.exportVollstaendigeAusfuhrAnmeldung"
                  class="mt-0 pt-0"
                  hide-details
                  @blur="removeEditable"
                  :error-messages="errors"
                ></v-checkbox>
              </ValidationProvider>
            </EditableField>
          </template>

          <template v-slot:item.exportVersandanmeldungNCTSDE="{ item }">
            <EditableField
              v-slot="{ removeEditable }"
              :id="'exportVersandanmeldungNCTSDE-' + item.id"
            >
              <ValidationProvider
                name="Export Versandanmeldung NCTSDE"
                :vid="'exportVersandanmeldungNCTSDE-' + item.id"
                rules="required"
                v-slot="{ errors }"
              >
                <v-checkbox
                  v-model="item.exportVersandanmeldungNCTSDE"
                  class="mt-0 pt-0"
                  hide-details
                  @blur="removeEditable"
                  :error-messages="errors"
                ></v-checkbox>
              </ValidationProvider>
            </EditableField>
          </template>

          <template v-slot:item.importUeberlassungsmeldungNCTSDE="{ item }">
            <EditableField
              v-slot="{ removeEditable }"
              :id="'importUeberlassungsmeldungNCTSDE-' + item.id"
            >
              <ValidationProvider
                name="Import Überlassungsmeldung NCTSDE"
                :vid="'importUeberlassungsmeldungNCTSDE-' + item.id"
                rules="required"
                v-slot="{ errors }"
              >
                <v-checkbox
                  v-model="item.importUeberlassungsmeldungNCTSDE"
                  class="mt-0 pt-0"
                  hide-details
                  @blur="removeEditable"
                  :error-messages="errors"
                ></v-checkbox>
              </ValidationProvider>
            </EditableField>
          </template>

          <template v-slot:item.importUeberlassungAusfuhr="{ item }">
            <EditableField
              v-slot="{ removeEditable }"
              :id="'importUeberlassungAusfuhr-' + item.id"
            >
              <ValidationProvider
                name="Import Überlassung Ausfuhr"
                :vid="'importUeberlassungAusfuhr-' + item.id"
                rules="required"
                v-slot="{ errors }"
              >
                <v-checkbox
                  v-model="item.importUeberlassungAusfuhr"
                  class="mt-0 pt-0"
                  hide-details
                  @blur="removeEditable"
                  :error-messages="errors"
                ></v-checkbox>
              </ValidationProvider>
            </EditableField>
          </template>

          <template v-slot:item.importErledigungsmeldungNCTSDE="{ item }">
            <EditableField
              v-slot="{ removeEditable }"
              :id="'importErledigungsmeldungNCTSDE-' + item.id"
            >
              <ValidationProvider
                name="Import Erledigungsmeldung NCTSDE"
                :vid="'importErledigungsmeldungNCTSDE-' + item.id"
                rules="required"
                v-slot="{ errors }"
              >
                <v-checkbox
                  v-model="item.importErledigungsmeldungNCTSDE"
                  class="mt-0 pt-0"
                  hide-details
                  @blur="removeEditable"
                  :error-messages="errors"
                ></v-checkbox>
              </ValidationProvider>
            </EditableField>
          </template>
          <template v-slot:item.customerId="{ item }">
            <EditableField
              v-slot="{ makeEditable, removeEditable, isEditable }"
              :id="'customerId-' + item.id"
            >
              <ValidationProvider
                name="Customer"
                :vid="'customerId-' + item.id"
                rules="required"
                v-slot="{ errors, failed }"
              >
                <div
                  @click="makeEditable"
                  :class="{ red: failed && !isEditable }"
                >
                  <div v-if="!isEditable">
                    {{ getCustomerName(item) }}
                  </div>
                  <v-autocomplete
                    v-if="isEditable || !item.customerId"
                    v-model="item.customerId"
                    :items="customers"
                    item-text="shortName"
                    item-value="id"
                    label="Customer"
                    single-line
                    :error-messages="errors"
                    @blur="removeEditable"
                  ></v-autocomplete>
                </div>
              </ValidationProvider>
            </EditableField>
          </template>
          <template v-slot:item.name="{ item }">
            <EditableField
              v-slot="{ makeEditable, removeEditable, isEditable }"
              :id="'name-' + item.id"
            >
              <ValidationProvider
                name="Name"
                :vid="'name-' + item.id"
                rules="required|max:20"
                v-slot="{ errors, failed }"
                slim
              >
                <div
                  @click="makeEditable"
                  :class="{ red: failed && !isEditable }"
                >
                  <div v-if="!isEditable">{{ item.name }}</div>
                  <v-text-field
                    v-if="isEditable || !item.name"
                    v-model="item.name"
                    label="Name"
                    single-line
                    counter
                    :error-messages="errors"
                    @blur="removeEditable"
                  ></v-text-field>
                </div>
              </ValidationProvider>
            </EditableField>
          </template>
          <template v-slot:item.exportAnsprechpartnerEmailAdresse="{ item }">
            <EditableField
              v-slot="{ makeEditable, removeEditable, isEditable }"
              :id="'email-' + item.id"
            >
              <ValidationProvider
                name="Email"
                :vid="'email-' + item.id"
                rules="required|email"
                v-slot="{ errors, failed }"
              >
                <div
                  @click="makeEditable"
                  :class="{ red: failed && !isEditable }"
                >
                  <div v-if="!isEditable">
                    {{ item.exportAnsprechpartnerEmailAdresse }}
                  </div>
                  <v-text-field
                    v-if="isEditable || !item.exportAnsprechpartnerEmailAdresse"
                    v-model="item.exportAnsprechpartnerEmailAdresse"
                    class="mt-0 pt-0"
                    label="Email"
                    single-line
                    :error-messages="errors"
                    autofocus
                    @blur="removeEditable"
                  ></v-text-field>
                </div>
              </ValidationProvider>
            </EditableField>
          </template>

          <template v-slot:item.exportAnsprechpartnerTelefonnummer="{ item }">
            <EditableField
              v-slot="{ makeEditable, removeEditable, isEditable }"
              :id="'Telefonnummer-' + item.id"
            >
              <ValidationProvider
                name="Phone Number"
                :vid="'Telefonnummer-' + item.id"
                rules="required|numeric"
                v-slot="{ errors, failed }"
              >
                <div
                  @click="makeEditable"
                  :class="{ red: failed && !isEditable }"
                >
                  <div v-if="!isEditable">
                    {{ item.exportAnsprechpartnerTelefonnummer }}
                  </div>
                  <v-text-field
                    v-if="
                      isEditable || !item.exportAnsprechpartnerTelefonnummer
                    "
                    v-model="item.exportAnsprechpartnerTelefonnummer"
                    class="mt-0 pt-0"
                    label="Phone Number"
                    single-line
                    :error-messages="errors"
                    autofocus
                    @blur="removeEditable"
                  ></v-text-field>
                </div>
              </ValidationProvider>
            </EditableField>
          </template>
          <template v-slot:item.teilnehmerEORI="{ item }">
            <EditableField
              v-slot="{ makeEditable, removeEditable, isEditable }"
              :id="'teilnehmerEORI-' + item.id"
            >
              <ValidationProvider
                name="Teilnehmer EORI"
                :vid="'teilnehmerEORI-' + item.id"
                rules="required"
                v-slot="{ errors, failed }"
                slim
              >
                <div
                  @click="makeEditable"
                  :class="{ red: failed && !isEditable }"
                >
                  <div v-if="!isEditable || item.teilnehmerEORI">
                    {{ item.teilnehmerEORI }}
                  </div>
                  <v-text-field
                    v-if="isEditable || !item.teilnehmerEORI"
                    v-model="item.teilnehmerEORI"
                    label="Name"
                    single-line
                    counter
                    :error-messages="errors"
                    @blur="removeEditable"
                  ></v-text-field>
                </div>
              </ValidationProvider>
            </EditableField>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import EditableField from "@/components/EditableField.vue.html";
import {
  CustomsDakosyExportCustomersApi,
  CustomsDakosyExportMenuViewModel,
  DataApi,
  CustomerViewModel,
} from "../openapi";
import { emitError } from "@/event-bus";
import { ValidationProvider } from "vee-validate";

const emit = defineEmits(["PageInfoReceived"]);
const dataApi = new DataApi(undefined, "");
const customsDakosyExportApi = new CustomsDakosyExportCustomersApi(
  undefined,
  "",
);

const headers = ref([
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
  { text: "Customer ID", value: "customerId" },
  { text: "Name", value: "name" },
  {
    text: "Export Vollstaendige Ausfuhr Anmeldung",
    value: "exportVollstaendigeAusfuhrAnmeldung",
  },
  {
    text: "Export Versandanmeldung NCTS DE",
    value: "exportVersandanmeldungNCTSDE",
  },
  {
    text: "Import Ueberlassungsmeldung NCTS DE",
    value: "importUeberlassungsmeldungNCTSDE",
  },
  {
    text: "Import Ueberlassung Ausfuhr",
    value: "importUeberlassungAusfuhr",
  },
  {
    text: "Import Erledigungsmeldung NCTS DE",
    value: "importErledigungsmeldungNCTSDE",
  },
  { text: "Teilnehmer EORI", value: "teilnehmerEORI" },
  {
    text: "AnsprechpartnerEmailAdresse",
    value: "exportAnsprechpartnerEmailAdresse",
  },
  {
    text: "AnsprechpartnerTelefonnummer",
    value: "exportAnsprechpartnerTelefonnummer",
  },
  { text: "Actions", value: "actions", sortable: false, width: "6em" },
]);

const isLoading = ref(false);
const disableNewItemButton = ref(false);
const footerOptions = ref({
  showFirstLastPage: true,
  itemsPerPageOptions: [25, 50, 100, -1],
  disablePagination: false,
});
const options = ref({
  page: 1,
  itemsPerPage: 100,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: false,
  mustSort: false,
});
const items = ref<CustomsDakosyExportMenuViewModel[]>([]);
const customers = ref<CustomerViewModel[]>([]);
const totalItems = ref(0);

const loadItems = (
  page: number,
  itemsPerPage: number,
  orderByField: string,
  orderBy: string,
) => {
  isLoading.value = true;
  footerOptions.value.disablePagination = true;
  customsDakosyExportApi
    .getDakosyExportMenuHeaders(itemsPerPage, page, orderByField, orderBy)
    .then((result) => {
      items.value = result.data.items as CustomsDakosyExportMenuViewModel[];
      totalItems.value = result.data.totalItems as number;
    })
    .finally(() => {
      isLoading.value = false;
      footerOptions.value.disablePagination = false;
    });
};

const loadCustomers = () => {
  dataApi.getCustomers(true).then((result) => {
    customers.value = result.data;
  });
};

const saveItem = (item: CustomsDakosyExportMenuViewModel) => {
  isLoading.value = true;
  customsDakosyExportApi
    .saveDakosyExportMenuHeader(item)
    .then(() => {
      loadItems(options.value.page, options.value.itemsPerPage, "", "");
    })
    .catch((error) => {
      emitError(error);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const deleteItem = (item: CustomsDakosyExportMenuViewModel) => {
  isLoading.value = true;
  customsDakosyExportApi
    .deleteDakosyExportMenuHeader(item.customerId)
    .then(() => {
      loadItems(options.value.page, options.value.itemsPerPage, "", "");
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const onUpdateOptions = (newOptions: any) => {
  options.value = newOptions;
  loadItems(
    options.value.page,
    options.value.itemsPerPage,
    options.value.sortBy[0],
    options.value.sortDesc[0] ? "desc" : "asc",
  );
};

const addNewItem = (item: CustomsDakosyExportMenuViewModel) => {
  disableNewItemButton.value = true;
  items.value.unshift({
    id: 0,
    customerId: 0,
    exportVollstaendigeAusfuhrAnmeldung: false,
    exportVersandanmeldungNCTSDE: false,
    importUeberlassungsmeldungNCTSDE: false,
    importUeberlassungAusfuhr: false,
    importErledigungsmeldungNCTSDE: false,
  });
};

emit("PageInfoReceived", "Dakosy definitions for Export shipments");

const getCustomerName = (item: CustomsDakosyExportMenuViewModel) => {
  var customer = customers.value.find((c) => c.id === item.customerId);
  if (!customer) {
    return "";
  }
  return customer.shortName;
};

onMounted(() => {
  loadCustomers();
  loadItems(options.value.page, options.value.itemsPerPage, "", "");
});
</script>
