import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue.html";
import AddLtsNumberDialog from "./dialogs/AddLtsNumberDialog.vue.html";
import CustomsFlowStepsMixin from "@/mixins/CustomsFlowStepsMixin";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue.html";
import { getStatuses } from "@/helpers/statusHelper";
import {
  CustomsReadyForTransitShipmentItemViewModel,
  CustomsShipmentItemViewModel,
  CustomsShipmentDetailViewModel,
  CustomsTransitApi,
  CustomsExportApi,
} from "@/openapi";
import ShipmentPaperwork from "./ShipmentPaperwork.vue.html";
import CountriesMixin from "@/mixins/CountriesMixin";
import ToolbarMixin from "@/mixins/ToolbarMixin";
import FilterParcels from "./FilterParcels.vue.html";

const customsTransitApi = new CustomsTransitApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");
@Component({
  components: {
    CustomsExportShipmentDetailViewNew,
    AddLtsNumberDialog,
    ShipmentStatusFlow,
    ShipmentPaperwork,
    FilterParcels,
  },
})
export default class CustomsExportReadyForTransit extends mixins(
  CustomsFlowStepsMixin,
  CountriesMixin,
  ToolbarMixin,
) {
  @Prop({ default: "" })
  color!: string;

  isLoading = false;
  options: any = {
    page: 1,
    itemsPerPage: 5,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };
  filter: any = {
    step: null,
    shipmentStatusDescription: "",
    parcels: [],
    exportMrn: "",
    shipperCountry: null,
    consigneeCountry: null,
  };
  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [5, 25, 50, 100],
    disablePagination: false,
  };
  totalItems: Number = 0;
  expanded = [];

  loadingSimulateAutomatedStep = false;

  checkShipmentStep = 2;
  automatedSteps = [6, 9, 10, 11, 13]; // for testing

  statusFilter: number[] = [];
  shipmentStatusFilter = [
    "Hold",
    "Return",
    "Customs inspection",
    "Loading confirmed",
    "Waiting for customs release",
    "Customs EXIT released",
  ];

  items: CustomsReadyForTransitShipmentItemViewModel[] = [];
  currentHandlingItem: CustomsReadyForTransitShipmentItemViewModel | null =
    null;
  currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
  currentLtsNumberItem: CustomsReadyForTransitShipmentItemViewModel | null =
    null;

  showDialog = false;
  isDialogLoading = false;

  headers = [
    {
      text: "Step",
      value: "exportFlowStatus",
      align: "center",
      sortable: false,
    },
    {
      text: "Shipment status",
      value: "shipmentStatusDescription",
      sortable: false,
    },
    {
      text: "HAWB/Parcel ID",
      value: "hawb",
      sortable: false,
      width: "15em",
    },
    {
      text: "MRN ",
      value: "exportMrn",
      sortable: false,
      width: "18em",
    },
    {
      text: "LTS",
      value: "lts",
      sortable: false,
    },
    {
      text: "Anzahl ",
      value: "pieces",
      sortable: false,
      align: "end",
    },
    {
      text: "Gewicht ",
      value: "grossWeight",
      sortable: false,
      align: "end",
    },
    { text: "EORI", value: "eoriNumber", sortable: false },
    {
      text: "Versender",
      value: "shipper",
      sortable: false,
      width: "15em",
    },
    {
      text: "Empfänger",
      value: "consignee",
      sortable: false,
      width: "15em",
    },
    {
      text: "Warenwert",
      value: "value",
      sortable: false,
      align: "end",
    },
    { text: "Währung", value: "valueCurrency", sortable: false },
    {
      text: "Warenbeschreibung / tarifnummer",
      value: "articles",
      sortable: false,
      width: "25em",
    },
  ];

  created() {
    this.statusFilter = getStatuses();

    this.addRefreshToolbarOption(this.reloadShipments);
  }

  async loadShipments(page: number) {
    this.isLoading = true;
    try {
      const response = await customsTransitApi.getReadyForTransitShipments({
        page,
        itemsPerPage: this.options.itemsPerPage,
        step: this.filter.step ?? undefined,
        shipmentStatusDescription:
          this.filter.shipmentStatusDescription ?? undefined,
        parcels: this.filter.parcels,
        exportMrn: this.filter.exportMrn ?? undefined,
        shipperCountry: this.filter.shipperCountry ?? undefined,
        consigneeCountry: this.filter.consigneeCountry ?? undefined,
      });

      this.items = response.data.items ?? [];
      this.totalItems = response.data.totalAmountOfItems ?? 0;
    } catch {
      this.displayError("Something went wrong while retrieving the shipments");
    }
    this.isLoading = false;
  }

  async reloadShipments() {
    this.options.page == 1
      ? await this.loadShipments(this.options.page)
      : (this.options.page = 1);
  }

  timeoutDelay = 0;
  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.loadShipments(this.options.page);
    }, 250);
  }

  @Watch("expanded")
  onExpandedChanged(
    newVal: CustomsReadyForTransitShipmentItemViewModel[],
    oldVal: CustomsReadyForTransitShipmentItemViewModel[],
  ) {
    if (newVal.length === 0) {
      this.currentHandlingItem = null;
      this.currentShipmentDetail = null;
    } else {
      this.currentHandlingItem = newVal[0];

      this.isLoading = true;
      this.currentShipmentDetail = null;
      customsExportApi
        .getCustomsShipmentDetail(this.currentHandlingItem.id!)
        .then((response) => (this.currentShipmentDetail = response.data))
        .catch((error) =>
          this.displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (this.isLoading = false));
    }
  }

  onFilterSelected() {
    this.reloadShipments();
  }

  displayError(errorMessage: string) {
    this.$emit("errorOccured", errorMessage);
  }

  openDialogAddLts(item: CustomsReadyForTransitShipmentItemViewModel) {
    this.showDialog = true;
    this.currentLtsNumberItem = item;
  }

  onDialogClose() {
    this.currentLtsNumberItem = null;
  }

  async onDialogConfirm({ ltsNumber }: { ltsNumber: string }) {
    this.isDialogLoading = true;
    try {
      await customsTransitApi.addLtsNumber({
        shipmentId: this.currentLtsNumberItem!.id ?? 0,
        ltsNumber: ltsNumber,
      });

      this.loadShipments(this.options.page);
    } catch {
      this.displayError(
        `Someting went wrong while adding the LTS number "${ltsNumber}"`,
      );
    }

    this.showDialog = false;
    this.isDialogLoading = false;
  }

  getItemClass(item: CustomsReadyForTransitShipmentItemViewModel) {
    return item.isOverdue ? "transit-ready-shipment-overdue" : "";
  }
}
