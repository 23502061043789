import { Component, Vue, Watch } from "vue-property-decorator";
import { ToolbarItem } from "../models/ToolbarItem";
import { ValidationResult } from "vee-validate/dist/types/types";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  CustomerViewModel,
  CustomsExportInformationApi,
  CustomsExportInformationViewModel,
  DataApi,
  KeyValueItem,
  CustomsCountryViewModel,
  CustomsCountriesApi,
} from "../openapi";

@Component({})
export default class CustomsExportInformation extends Vue {
  dataApi: DataApi = new DataApi(undefined, "");
  exportInformationApi: CustomsExportInformationApi =
    new CustomsExportInformationApi(undefined, "");
  customsCountriesApi: CustomsCountriesApi = new CustomsCountriesApi(
    undefined,
    "",
  );

  headers = [
    { text: "Name", value: "name", width: "200px" },
    { text: "Customer", align: "start", value: "customerId" },
    { text: "CONSO file country", align: "start", value: "consoFileCountry" },
    { text: "Country Route", value: "customsExportInformationRouteIds" },
    { text: "Manifest Name", value: "manifestName" },
    { text: "Versender Name", value: "versenderName" },
    {
      text: "Versender Strasse Hausnummer",
      value: "versenderStrasseHausnummer",
    },
    { text: "Versender Ort", value: "versenderOrt" },
    { text: "Versender PLZ", value: "versenderPLZ" },
    { text: "Versender Land", value: "versenderLand" },
    { text: "Anmelde Art Ausfuhr Code", value: "anmeldeArtAusfuhrCode" },
    {
      text: "Anmelde Art Ueberfuehrung Code",
      value: "anmeldeArtUeberfuehrungCode",
    },
    { text: "Verkehrszweig Grenze", value: "verkehrszweigGrenze" },
    {
      text: "Befoerderungsmittel Grenze Art Code",
      value: "befoerderungsmittelGrenzeArtCode",
    },
    { text: "Verkehrszweig Inland", value: "verkehrszweigInland" },
    { text: "Ausfuhr Zollstelle Id", value: "ausfuhrZollstelleID" },
    { text: "Ausgangs Zollstelle ID", value: "ausgangZollstelleId" },
    { text: "Verfahren Angemeldet Code", value: "verfahrenAngemeldetCode" },
    {
      text: "Verfahren Vorangegangen Code",
      value: "verfahrenVorangegangenCode",
    },
    { text: "Zusatz", value: "zusatz" },
    {
      text: "Ansprechpartner Email Adresse",
      value: "ansprechpartnerEmailAdresse",
    },
    { text: "Actions", value: "actions", sortable: false, width: "6em" },
  ];
  isLoading = false;
  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [25, 50, 100, -1],
    disablePagination: false,
  };
  options: any = {
    page: 1,
    itemsPerPage: 100,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: false,
  };
  items: CustomsExportInformationViewModel[] = [];
  totalItems: Number = 0;
  timeoutDelay: any = null;
  disableNewItemButton: Boolean = false;
  customers: CustomerViewModel[] = [];
  customsCountries: CustomsCountryViewModel[] = [];
  consoFileCountries: KeyValueItem[] = [];

  created() {
    var toolbarButtons: ToolbarItem[] = [
      {
        callback: () => this.refresh(),
        icon: "mdi-refresh",
        tooltipText: "Refresh overview",
      },
    ];
    this.$emit(
      "PageInfoReceived",
      "Dakosy definitions for Export shipments",
      toolbarButtons,
    );

    this.getCustomsCountries();
    this.getConsoFileCountries();

    this.dataApi.getCustomers(true).then((result) => {
      this.customers = result.data;
    });
  }

  @Watch("options")
  onOptionsChanged(newVal: any, oldVal: any) {
    clearTimeout(this.timeoutDelay);
    this.timeoutDelay = setTimeout(() => {
      this.disableNewItemButton = false;
      this.loadItems(
        newVal.page,
        newVal.itemsPerPage,
        this.getSort(newVal.sortDesc),
        this.getSortField(newVal.sortBy),
      );
    }, 250);
  }

  loadItems(
    page: number,
    itemsPerPage: number,
    orderByField: string,
    orderBy: string,
  ) {
    this.isLoading = true;
    this.footerOptions.disablePagination = true;
    this.exportInformationApi
      .getCustomsExportInformation(itemsPerPage, page, orderBy, orderByField)
      .then((result) => {
        this.items = result.data.items as CustomsExportInformationViewModel[];
        this.totalItems = result.data.totalItems as number;
      })
      .finally(() => {
        this.isLoading = false;
        this.footerOptions.disablePagination = false;
      });
  }

  getCustomsCountries() {
    this.customsCountries = [];

    this.customsCountriesApi
      .getCustomsCountries()
      .then((response) => {
        this.customsCountries = response.data;
      })
      .catch((error) => {});
  }

  async getConsoFileCountries() {
    try {
      var response = await this.exportInformationApi.getConsoFileCountries();
      this.consoFileCountries = response.data;
    } catch {
      this.$emit("errorOccured", [
        "Something went wrong while retrieving the ConsoFile countries.",
      ]);
    }
  }

  async saveItem(item: CustomsExportInformationViewModel) {
    if (this.isLoading) {
      return;
    }

    var validationObserver = (<Vue[]>(
      this.$refs["observer-" + item.id]
    ))[0] as InstanceType<typeof ValidationObserver>;
    const promises: Promise<ValidationResult>[] = [];
    for (const key in this.$refs) {
      const split = key.split("-");
      if (!split.length || key === "observer-" + item.id) {
        continue;
      }

      const propName = split[0];
      const id = parseInt(split[1]);
      if (id != item.id) {
        continue;
      }

      const validationProvider = (<Vue[]>this.$refs[key])[0] as InstanceType<
        typeof ValidationProvider
      >;
      promises.push(
        validationProvider.validate(
          item[propName as keyof CustomsExportInformationViewModel],
        ),
      );
    }
    validationObserver.validate().then(async (isValid) => {
      const validationResults = await Promise.all(promises);
      let errorsArray: { id: string; errors: string[] }[] = [];
      Object.keys(validationObserver.errors).map((key) => {
        if (validationObserver.errors[key].length > 0) {
          errorsArray.push({
            id: key,
            errors: validationObserver.errors[key],
          });
        }
      });
      for (var validationResult of validationResults) {
        if (!validationResult.valid) {
          var errorObject = errorsArray.find(
            (o) => o.errors.indexOf(validationResult.errors[0]) !== -1,
          );
          if (errorObject) {
            var errorElementId = errorObject?.id;
            document
              .getElementById(errorElementId)
              ?.scrollIntoView({ inline: "start" });
          }

          return;
        }
      }

      this.isLoading = true;
      const isNewItem = this.isNewItem(item);

      this.exportInformationApi
        .saveCustomsExportInformation(item)
        .then((result) => {
          if (!result?.data) {
            return;
          }

          this.mapItem(item, result.data);
          if (isNewItem) {
            this.disableNewItemButton = false;
          }

          validationObserver.reset();
        })
        .catch((error) => {
          this.$emit("errorOccured", [error.message]);
        })
        .finally(() => {
          this.isLoading = false;
        });
    });
  }

  deleteItem(item: any) {
    this.isLoading = true;
    const index = this.items.findIndex((cdec) => cdec.id === item.id);
    if (this.isNewItem(item)) {
      this.items.splice(index, 1);
      this.isLoading = false;
      this.disableNewItemButton = false;
      return;
    }

    this.exportInformationApi
      .deleteCustomsExportInformation(item.id)
      .then((c) => {
        this.items.splice(index, 1);
      })
      .catch(() => {})
      .finally(() => {
        const options = this.options;
        this.loadItems(
          options.page,
          options.itemsPerPage,
          this.getSort(options.sortDesc),
          this.getSortField(options.sortBy),
        );
      });
  }

  addNewItem() {
    this.disableNewItemButton = true;
    this.items.unshift({ id: 0 } as CustomsExportInformationViewModel);
  }

  isNewItem(item: CustomsExportInformationViewModel): Boolean {
    return item?.id === 0;
  }

  getSortField(sortFields: string[]) {
    return sortFields[0] ?? "";
  }

  getSort(sortDesc: Boolean[]) {
    let isDesc = sortDesc[0] ?? null;
    if (!(isDesc === false || isDesc === true)) {
      return "";
    }
    return isDesc ? "DESC" : "ASC";
  }

  mapItem(oldItem: any, newItem: any) {
    for (const key in oldItem) {
      oldItem[key] = newItem[key];
    }
  }

  refresh() {
    this.loadItems(
      this.options.page,
      this.options.itemsPerPage,
      this.getSort(this.options.sortDesc),
      this.getSortField(this.options.sortBy),
    );
    this.disableNewItemButton = false;
  }

  getCustomerName(item: CustomsExportInformationViewModel) {
    var customer = this.customers.find((c) => c.id === item.customerId);
    if (!customer) {
      return "";
    }

    return customer.shortName;
  }

  getCountryName(id: number) {
    var country = this.customsCountries.find((c) => c.id === id);
    if (!country) {
      return "";
    }

    return country.description;
  }

  getCountryNames(ids: number[]) {
    if (!ids || ids.length == 0) return;

    var countryNames = this.getCountryName(ids[0]);
    for (var i = 1; i < ids.length; i++) {
      countryNames += " ➔ " + this.getCountryName(ids[i]);
    }

    return countryNames;
  }

  getConsoFileCountryName(key: number) {
    return this.consoFileCountries.find((c) => c.key === key)?.value;
  }
}
