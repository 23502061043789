import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { FileDownloadApi } from "@/openapi";
const fileDownloadApi = new FileDownloadApi(undefined, "");
@Component({})
export default class FileDownload extends Vue {
  drawer = false;
  fileDates: string[] = [];
  downloadUnsuccessful: boolean = false;

  headers = [
    {
      text: "Date",
    },
  ];

  created() {
    this.$emit("PageInfoReceived", "Download files", []);

    this.loadData();
  }

  loadData() {
    fileDownloadApi.listFileDates().then((response) => {
      this.fileDates = response.data;
    });
  }

  downloadFile(date: string) {
    window.open(
      `api/FileDownload/downloadFiles/?date=${encodeURIComponent(date)}`,
      "_blank",
    );
  }
}
