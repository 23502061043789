import { RouteConfig } from "vue-router";
import Customs from "@/views/Customs.vue";
import CustomsExport from "../views/CustomsExport.vue.html";
import Login from "../views/Login.vue.html";
import FileDownload from "../views/FileDownload.vue.html";
import ArticlesPage from "../views/ArticlesPage.vue";
import DakosyExport from "../views/DakosyExport.vue.html";
import DakosyExportMenu from "../views/DakosyExportMenu.vue";
import InboundFreightcosts from "../views/CustomsOfficeCustomers.vue.html";
import WarehouseLocations from "../views/WarehouseLocations.vue.html";
import CustomsOfficeExportFlow from "../views/CustomsOfficeExportFlow.vue.html";
import CustomsExportInformation from "../views/CustomsExportInformation.vue.html";
import Employees from "../views/Employees.vue";
import ReturnShipments from "../views/ReturnShipments.vue.html";
import FlightHandling from "../views/FlightHandling.vue";
import PaymentReminderSettings from "../views/PaymentReminderSettings.vue.html";
import PaymentReminderEmails from "../views/PaymentReminderEmails.vue.html";
import CustomsCountries from "../views/CustomsCountries.vue.html";
import CustomsDestinationDistricts from "../views/CustomsDestinationDistricts.vue.html";
import CustomsCountriesEU from "../views/CustomsCountriesEU.vue.html";
import CustomsTypesOfBusiness from "../views/CustomsTypesOfBusiness.vue.html";
import CustomsOffices from "@/components/administration/CustomsOffices.vue";
import DocumentTypes from "@/components/administration/DocumentTypes.vue";
import CustomsTransportTypes from "../views/CustomsTransportTypes.vue.html";
import CustomsCodes from "../views/CustomsCodes.vue.html";
import CustomsDeliveryConditions from "../views/CustomsDeliveryConditions.vue.html";
import CustomsKeywords from "../views/CustomsKeywords.vue";
import CustomsKostenstellen from "../views/CustomsKostenstellen.vue.html";
import CustomsInvoiceTaxKeys from "../views/CustomsInvoiceTaxKeys.vue.html";
import CustomsInvoiceCategories from "../views/CustomsInvoiceCategories.vue.html";
import BookingAccounts from "../views/BookingAccounts.vue.html";
import AddClaim from "../views/AddClaim.vue.html";
import Claims from "../views/Claims.vue.html";
import Home from "../views/Home.vue.html";
import CustomsForbiddenTarif from "@/views/CustomsForbiddenTarif.vue.html";
import CustomsExportCustomers from "@/views/CustomsExportCustomers.vue.html";
import CustomsMilestoneHandling from "@/views/CustomsMilestoneHandling.vue.html";
import CustomsStatusCustomers from "@/views/CustomsStatusCustomers.vue.html";
import CustomsArticleMaintenance from "@/views/CustomsArticleMaintenance.vue.html";
import Schemes from "../views/Schemes.vue";
import CustomerManagement from "../views/CustomerManagement.vue";
import Kpis from "../views/dashboards/Kpis.vue";
import EmailTemplate from "../views/EmailTemplate.vue";
import Ratecards from "../views/Ratecards.vue.html";
import BillingServices from "../views/BillingServices.vue.html";
import CustomsTransitDeclarationManagement from "@/views/CustomsTransitDeclarationManagement.vue";
import PreCheckShipments from "@/views/PreCheckShipments.vue";
import CsvInvoiceExportTemplates from "../views/CsvInvoiceExportTemplates.vue";
import DutiesAndTaxes from "@/views/DutiesAndTaxes.vue";
import MrnDiscrepancies from "@/views/MrnDiscrepancies.vue";
import PaymentTerms from "@/components/PaymentTerms.vue";
import InvoiceFooterTexts from "@/components/InvoiceFooterTexts.vue";
import Currencies from "@/views/Currencies.vue";
import DistributedJobs from "@/views/admin/DistributedJobs.vue";
import HawbDefinitions from "@/components/HawbDefinitions.vue";
import ShipmentDetails from "@/views/shipmentDetail/ShipmentDetails.vue";
import ShipmentDetailsPage from "@/views/shipmentDetail/ShipmentDetailsPage.vue";
import DebtorNumbers from "@/views/DebtorNumbers.vue";
import EmployeeCustoms from "@/views/employees/EmployeeCustoms.vue";
import MasterOverview from "@/views/MasterOverview.vue";
import ProblemShipments from "@/views/ProblemShipments.vue";
import EmployeeOverview from "@/components/employee/EmployeeOverview.vue";
import EmployeeUserRights from "@/components/employee/EmployeeUserRights.vue";
import EmployeeDetail from "@/components/employee/EmployeeDetail.vue";
import EmployeeDetailData from "@/components/employee/EmployeeDetailData.vue";
import EmployeeDetailRights from "@/components/employee/EmployeeDetailRights.vue";
import EmployeeBulkAssignment from "@/components/employee/EmployeeBulkAssignment.vue";
import PassThrough from "@/views/PassThrough.vue";
import CustomsAusfuhrInfoList from "@/views/ausfuhr/CustomsAusfuhrInfoList.vue";
import CustomsAusfuhrInfoDetail from "@/views/ausfuhr/CustomsAusfuhrInfoDetail.vue";
import ArticleCategories from "@/views/ArticleCategories.vue";
import DocumentShipments from "@/components/DocumentShipments.vue";
import menuGroupPage from "@/components/logged-in/menuGroupPage.vue";
import ImportClearances from "@/views/dashboards/ImportClearances.vue";
import ContainerManagement from "@/views/ContainerManagement.vue";
import DatatransferErrors from "@/views/DatatransferErrors/DatatransferErrors.vue";
import Containers from "@/components/containers/Containers.vue";
import ContainerManifests from "@/components/containers/ContainerManifests.vue";
import FileImports from "@/views/FileImports.vue";
import FileImportList from "@/components/file-import/FileImportList.vue";
import FremdGestellung from "@/components/file-import/FremdGestellung.vue";

export interface MenuItem {
  name?: string;
  title?: string;
  path?: string | null;
  //component?: Component;
  userRights?: string;
  children?: MenuItem[];
  loggedIn?: boolean;
  isPage?: boolean;
  isChildPage?: boolean;
  show?: boolean;
  showInMenu?: boolean;
  //parent?: MenuItem | null;
  route?: RouteConfig | null;
}

export const allRoutes: MenuItem[] = [
  {
    name: "Login",
    loggedIn: false,
    showInMenu: false,
    route: { path: "/login", component: Login },
  },
  {
    name: "Home",
    showInMenu: false,
    route: { path: "/", component: Home },
  },
  {
    name: "Shipment overview",
    route: {
      path: "/shipment-overview",
    },
    children: [
      {
        name: "Import",
        route: { path: "/shipment-overview/customsimport", component: Customs },
      },
      {
        name: "Export",
        route: {
          path: "/shipment-overview/customsexport",
          component: CustomsExport,
        },
      },
      {
        name: "Shipment details",
        route: {
          path: "/shipment-overview/shipmentdetails",
          component: ShipmentDetails,
        },
      },
      {
        name: "ShipmentDetailsPage",
        showInMenu: false,
        route: {
          path: "/shipment-overview/shipmentdetails/shipmentdetailspage/:shipmentIds?",
          component: ShipmentDetailsPage,
          props: (route) => {
            let shipmentIds: number[] = [];
            if (route.params.shipmentIds) {
              shipmentIds = JSON.parse(route.params.shipmentIds);
            }
            return {
              shipmentIds: shipmentIds,
            };
          },
        },
      },
    ],
  },
  {
    name: "Operational Add-ons",
    route: {
      path: "/operational-add-ons",
    },
    children: [
      {
        name: "Download files",
        route: {
          path: "/operational-add-ons/filedownload",
          component: FileDownload,
        },
      },
      {
        name: "File import",
        isPage: true,
        route: {
          path: "/operational-add-ons/fileimport",
          redirect: { name: "File import list" },
          component: FileImports,
        },
        children: [
          {
            name: "File import list",
            isChildPage: true,
            route: {
              path: "",
              component: FileImportList,
            },
          },
          {
            name: "FremdGestellung",
            isChildPage: true,
            route: {
              path: "fremdgestellung",
              component: FremdGestellung,
            },
          },
        ],
      },
      {
        name: "Return shipments",
        route: {
          path: "/operational-add-ons/returnshipments",
          component: ReturnShipments,
        },
      },
      {
        name: "Flight Handling",
        route: {
          path: "/operational-add-ons/flighthandling",
          component: FlightHandling,
        },
      },
      {
        name: "Pre-Check shipments",
        route: {
          path: "/operational-add-ons/precheckshipments",
          component: PreCheckShipments,
        },
      },
      {
        name: "MRN discrepancies",
        route: {
          path: "/operational-add-ons/mrn-discrepancies",
          component: MrnDiscrepancies,
        },
      },
      {
        name: "Master Overview",
        route: {
          path: "/operational-add-ons/master-overview",
          component: MasterOverview,
        },
      },
      {
        name: "Problem shipments",
        route: {
          path: "/operational-add-ons/problem-shipments",
          component: ProblemShipments,
        },
      },
      {
        name: "Container management",
        isPage: true,
        route: {
          redirect: { name: "Containers" },
          path: "/operational-add-ons/container-management",
          component: ContainerManagement,
        },
        children: [
          {
            name: "Containers",
            isChildPage: true,
            showInMenu: false,
            route: {
              path: "/operational-add-ons/container-management/containers",
              component: Containers,
            },
          },
          {
            name: "ContainerManifests",
            isChildPage: true,
            showInMenu: false,
            route: {
              path: "/operational-add-ons/container-management/manifests",
              component: ContainerManifests,
            },
          },
        ],
      },
      {
        name: "Datatransfer errors",
        route: {
          path: "/operational-add-ons/datatransfer-errors",
          component: DatatransferErrors,
        },
      },
    ],
  },
  {
    name: "Financial",
    route: {
      path: "/financial",
    },
    children: [
      {
        name: "Claim management",
        route: {
          path: "/financial/claim-management",
        },
        children: [
          {
            name: "Add claim",
            route: {
              path: "/financial/claim-management/addclaim",
              component: AddClaim,
            },
          },
          {
            name: "Open claims",
            route: {
              path: "/financial/claim-management/claims",
              component: Claims,
            },
          },
        ],
      },
      {
        name: "Ratecards",
        route: {
          path: "/financial/ratecards",
          component: Ratecards,
        },
      },
      {
        name: "Bundeskasse",
        route: {
          path: "/financial/bundeskasse",
          component: DutiesAndTaxes,
        },
      },
      {
        name: "Debtor numbers",
        route: {
          path: "/financial/debtor-numbers",
          component: DebtorNumbers,
        },
      },
    ],
  },
  {
    name: "Maintenance",
    route: {
      path: "/maintenance",
    },
    children: [
      {
        name: "Operational",
        route: {
          path: "/maintenance/operational",
        },
        children: [
          {
            name: "Articles",
            route: {
              path: "/maintenance/operational/articles",
              component: ArticlesPage,
            },
          },
          {
            name: "Article Categories",
            route: {
              path: "/maintenance/operational/article-categories",
              component: ArticleCategories,
            },
          },
          {
            name: "Article Maintenance",
            route: {
              path: "/maintenance/operational/customs-article-maintenance",
              component: CustomsArticleMaintenance,
            },
          },
          {
            name: "Export Customers",
            route: {
              path: "/maintenance/operational/customs-export-customers",
              component: CustomsExportCustomers,
            },
          },
          {
            name: "Export Flows",
            route: {
              path: "/maintenance/operational/customs-office-export-flow",
              component: CustomsOfficeExportFlow,
            },
          },
          {
            name: "Forbidden Tarifs",
            route: {
              path: "/maintenance/operational/customs-forbidden-tarifs",
              component: CustomsForbiddenTarif,
            },
          },
          {
            name: "Keywords",
            route: {
              path: "/maintenance/operational/customs-keywords",
              component: CustomsKeywords,
            },
          },
          {
            name: "T1/T2 definitions",
            route: {
              path: "/maintenance/operational/transit-declaration-management",
              component: CustomsTransitDeclarationManagement,
            },
          },
          {
            name: "Document shipments",
            route: {
              path: "/maintenance/operational/document-shipments",
              component: DocumentShipments,
            },
          },
          {
            name: "Warenorte Export",
            route: {
              path: "/maintenance/operational/warenorte-export",
              component: CustomsAusfuhrInfoList,
            },
          },
          ///MOVE???
          {
            name: "MaintenanceOperationalCustomsAusfuhrInfoDetail",
            showInMenu: false,
            route: {
              path: "/warenorte-export/detail/:id?",
              component: CustomsAusfuhrInfoDetail,
              props: (route) => {
                let id: number | undefined;
                if (route.params.id) {
                  const parsedId = parseInt(route.params.id);

                  id = parsedId || undefined;
                }
                return {
                  id: id,
                };
              },
            },
          },
        ],
      },
      {
        name: "Warehousing / Scanner",
        route: {
          path: "/maintenance/warehousing-scanner",
        },
        children: [
          {
            name: "Warehouse locations",
            route: {
              path: "/maintenance/warehousing-scanner/warehouselocations",
              component: WarehouseLocations,
            },
          },
          {
            name: "HAWB definitions",
            route: {
              path: "/maintenance/warehousing-scanner/hawb-definitions",
              component: HawbDefinitions,
            },
          },
        ],
      },
      {
        name: "Employees & User Rights",
        route: {
          path: "/maintenance/employees-user-rights",
        },
        children: [
          {
            name: "Employees",
            route: {
              path: "/maintenance/employees-user-rights/employees",
              redirect: { name: "EmployeeOverview" },
              component: Employees,
            },
            isPage: true,
            children: [
              {
                name: "EmployeeOverview",
                isChildPage: true,
                route: {
                  path: "",
                  component: EmployeeOverview,
                },
                children: [],
              },
              {
                name: "EmployeeUserRights",
                isChildPage: true,
                route: {
                  path: "assigned-user-rights",
                  component: EmployeeUserRights,
                },
              },
            ],
          },
          {
            name: "Customs",
            route: {
              path: "/maintenance/employees/customs",
              component: EmployeeCustoms,
            },
          },
        ],
      },
      {
        name: "Maintenance Financial",
        title: "Financial",
        route: {
          path: "/maintenance/financial",
        },
        children: [
          {
            name: "Buchungskonten",
            route: {
              path: "/maintenance/financial/bookingaccounts",
              component: BookingAccounts,
            },
          },
          {
            name: "Csv invoice export templates",
            route: {
              path: "/maintenance/financial/csvinvoiceexporttemplates",
              component: CsvInvoiceExportTemplates,
            },
          },
          {
            name: "Currencies",
            route: {
              path: "/maintenance/financial/currencies",
              component: Currencies,
            },
          },
          {
            name: "Customs Invoice Categories",
            route: {
              path: "/maintenance/financial/customsinvoicecategories",
              component: CustomsInvoiceCategories,
            },
          },
          {
            name: "Customs Invoice Tax Keys",
            route: {
              path: "/maintenance/financial/customsinvoicetaxkeys",
              component: CustomsInvoiceTaxKeys,
            },
          },
          {
            name: "Customs Kostenstellen",
            route: {
              path: "/maintenance/financial/customskostenstellen",
              component: CustomsKostenstellen,
            },
          },
          {
            name: "Invoice Footer Texts",
            route: {
              path: "/maintenance/financial/invoicefootertexts",
              component: InvoiceFooterTexts,
            },
          },
          {
            name: "Payment Reminder Emails",
            route: {
              path: "/maintenance/financial/paymentreminderemails",
              component: PaymentReminderEmails,
            },
          },
          {
            name: "Payment Reminder Settings",
            route: {
              path: "/maintenance/financial/paymentremindersettings",
              component: PaymentReminderSettings,
            },
          },
          {
            name: "Payment Terms",
            route: {
              path: "/maintenance/financial/paymentterms",
              component: PaymentTerms,
            },
          },
          {
            name: "Services for billing",
            route: {
              path: "/maintenance/financial/billingservices",
              component: BillingServices,
            },
          },
        ],
      },
      {
        name: "Administration",
        route: {
          path: "/maintenance/administration",
        },
        children: [
          {
            name: "Countries",
            route: {
              path: "/maintenance/administration/customscountries",
              component: CustomsCountries,
            },
          },
          {
            name: "Countries EU",
            route: {
              path: "/maintenance/administration/customscountrieseu",
              component: CustomsCountriesEU,
            },
          },
          {
            name: "Customs Codes",
            route: {
              path: "/maintenance/administration/customscodes",
              component: CustomsCodes,
            },
          },
          {
            name: "Delivery Conditions",
            route: {
              path: "/maintenance/administration/customscodes",
              component: CustomsDeliveryConditions,
            },
          },
          {
            name: "Districts",
            route: {
              path: "/maintenance/administration/customsdestinationdistricts",
              component: CustomsDestinationDistricts,
            },
          },
          {
            name: "Transport Types",
            route: {
              path: "/maintenance/administration/customstransporttypes",
              component: CustomsTransportTypes,
            },
          },
          {
            name: "Types Of Business",
            route: {
              path: "/maintenance/administration/customstypesofbusiness",
              component: CustomsTypesOfBusiness,
            },
          },
          {
            name: "Document types",
            route: {
              path: "/maintenance/administration/documenttypes",
              component: DocumentTypes,
            },
          },
          {
            name: "Customs offices",
            route: {
              path: "/maintenance/administration/customsoffices",
              component: CustomsOffices,
            },
          },
        ],
      },
    ],
  },
  {
    name: "EDI Settings",
    route: {
      path: "/edi-settings",
    },
    children: [
      {
        name: "EDI Settings Import",
        title: "Import",
        route: {
          path: "/edi-settings/dakosyexport",
          component: DakosyExport,
        },
      },
      {
        name: "Export",
        route: {
          path: "/edi-settings/dakosyexportmenu",
          component: DakosyExportMenu,
        },
      },
      {
        name: "Export route information",
        route: {
          path: "/edi-settings/customsexportinformation",
          component: CustomsExportInformation,
        },
      },
      {
        name: "Inbound freightcosts",
        route: {
          path: "/edi-settings/inboundfreightcosts",
          component: InboundFreightcosts,
        },
      },
      {
        name: "Customs Milestone Handling",
        route: {
          path: "/edi-settings/customsmilestonehandling",
          component: CustomsMilestoneHandling,
        },
      },
      {
        name: "Customs status Customers",
        route: {
          path: "/edi-settings/customsstatuscustomers",
          component: CustomsStatusCustomers,
        },
      },
      {
        name: "EDI Settings Export Customers",
        title: "Export Customers",
        route: {
          path: "/edi-settings/customs-export-customers",
          component: CustomsExportCustomers,
        },
      },
    ],
  },
  {
    name: "Customer Settings",
    route: {
      path: "/customer-settings",
    },
    children: [
      {
        name: "Customer Management",
        route: {
          path: "/customer-settings/customermanagement",
          component: CustomerManagement,
        },
      },
      {
        name: "Schemes",
        route: {
          path: "/customer-settings/schemes",
          component: Schemes,
        },
      },
    ],
  },
  {
    name: "Templates",
    route: {
      path: "/templates",
    },
    children: [
      {
        name: "Email templates",
        route: {
          path: "/templates/emailtemplate",
          component: EmailTemplate,
        },
        children: [],
      },
    ],
  },
  {
    name: "Admin",
    route: {
      path: "/admin",
    },
    children: [
      {
        name: "Distributed import jobs",
        route: {
          path: "/admin/distributed-import-jobs",
          component: DistributedJobs,
        },
      },
    ],
  },
  {
    name: "Dashboards",
    route: {
      path: "/dashboards",
    },
    children: [
      {
        name: "ImportClearances",
        title: "Import Clearances",
        route: {
          path: "/dashboards/import-clearances",
          component: ImportClearances,
        },
        children: [],
      },
      {
        name: "Kpis",
        title: "KPIs",
        route: {
          path: "/dashboards/kpis",
          component: Kpis,
        },
        children: [],
      },
    ],
  },
  {
    name: "EmployeeBulkAssignment",
    showInMenu: false,
    route: {
      path: "/assign-bulk-user-rights",
      component: EmployeeBulkAssignment,
      props: (route) => {
        let employeeIds: number[] = [];
        if (route.params.employeeIds) {
          employeeIds = JSON.parse(route.params.employeeIds);
        }
        return {
          employeeIds: employeeIds,
        };
      },
    },
  },
  {
    name: "EmployeeDetail",
    showInMenu: false,
    route: {
      path: "/maintenance/employees/detail/:employeeId?",
      redirect: { name: "EmployeeDetailInfo" },
      component: EmployeeDetail,
      props: (route) => {
        let id: number | undefined;
        if (route.params.employeeId) {
          const parsedId = parseInt(route.params.employeeId);
          id = parsedId || undefined;
        }
        return {
          employeeId: id,
        };
      },
    },
    children: [
      {
        name: "EmployeeDetailInfo",
        isChildPage: true,
        showInMenu: false,
        route: {
          path: "/maintenance/employees/employee-detail-info",
          component: EmployeeDetailData,
          props: (route) => {
            let id: number | undefined;
            if (route.params.employeeId) {
              const parsedId = parseInt(route.params.employeeId);
              id = parsedId || undefined;
            }
            return {
              employeeId: id,
            };
          },
        },
      },
      {
        name: "EmployeeDetailRights",
        isChildPage: true,
        showInMenu: false,
        route: {
          path: "/maintenance/employees/user-rights",
          component: EmployeeDetailRights,
          props: (route) => {
            let id: number | undefined;
            if (route.params.employeeId) {
              const parsedId = parseInt(route.params.employeeId);
              id = parsedId || undefined;
            }
            return {
              employeeId: id,
            };
          },
        },
      },
    ],
  },
];

export const finalRoutes: RouteConfig[] = [];

const hasRouteComponent = (route: RouteConfig | null | undefined) => {
  if (route) {
    if ("component" in route) {
      return route.component != null && route.component != undefined;
    }
    if ("components" in route) {
      return route.components != null && route.components != undefined;
    }
  }
  return false;
};

const setMenuItem = (parentUserRights: string | null, menuItem: MenuItem) => {
  const hasRoute = !!menuItem.route;
  if (hasRoute) {
    menuItem.path = menuItem.route?.path;
  }
  if (!menuItem.title) {
    menuItem.title = menuItem.name;
  }

  if (!parentUserRights) {
    menuItem.userRights = menuItem.title ?? "";
  } else {
    menuItem.userRights = parentUserRights + "." + menuItem.title;
  }
  if (!menuItem.children) {
    menuItem.children = [];
  }
  if (menuItem.loggedIn == undefined || menuItem.loggedIn == null) {
    menuItem.loggedIn = true;
  }

  menuItem.isPage = hasRoute && hasRouteComponent(menuItem.route);

  if (menuItem.isChildPage != true) {
    menuItem.isChildPage = false;
  }
  menuItem.show = false;
  if (menuItem.showInMenu != false) {
    menuItem.showInMenu = true;
  }

  menuItem.children?.forEach((subitem) => {
    setMenuItem(menuItem.userRights ?? "", subitem);
  });

  if (menuItem.route) {
    menuItem.route.name = menuItem.name;
    menuItem.route.meta = {
      loggedIn: menuItem.loggedIn,
      userRights: menuItem.userRights,
    };
    // if (menuItem.name == "Employees") {
    //   debugger;
    // }

    const found = menuItem.children.filter((child) => {
      return child.isChildPage;
    });
    if (found) {
      menuItem.route.children = [];
      found.forEach((child) => {
        // if (menuItem.name == "Employees") {
        //   debugger;
        // }
        if (child.route) {
          menuItem.route?.children?.push(child.route);
        }
      });
    }
    if (!menuItem.isChildPage) {
      finalRoutes.push(menuItem.route);
    }
  }
};

const clearRouteValues = (menuItem: MenuItem) => {
  menuItem.route = null;
  menuItem.children?.forEach((subitem) => {
    clearRouteValues(subitem);
  });
};

allRoutes.forEach((menuItem) => {
  setMenuItem(null, menuItem);
});

allRoutes.forEach((menuItem) => {
  clearRouteValues(menuItem);
});
