import { Component, Vue, Watch } from "vue-property-decorator";
import { ToolbarItem } from "../models/ToolbarItem";
import {
  CustomerViewModel,
  DataApi,
  ReminderSettingsViewModel,
  PaymentReminderSettingsApi,
} from "../openapi";

@Component({})
export default class PaymentReminderSettings extends Vue {
  dataApi: DataApi = new DataApi(undefined, "");
  paymentReminderSettingsApi: PaymentReminderSettingsApi =
    new PaymentReminderSettingsApi(undefined, "");

  headers = [
    { text: " ", value: "actions", sortable: false, width: "6em" },
    { text: "Customer", value: "customerId" },
    { text: "First reminder after XX days", value: "daysUntilFirstReminder" },
    { text: "Second reminder after XX days", value: "daysUntilSecondReminder" },
    { text: "Third reminder after XX days", value: "daysUntilThirdReminder" },
    { text: "Percentage of interest", value: "interestPercentage" },
    { text: "Costs for reminder by post", value: "reminderByPostCosts" },
  ];

  isLoading = false;
  customers: CustomerViewModel[] = [];
  items: ReminderSettingsViewModel[] = [];

  disableNewItemButton: Boolean = false;
  totalItems: number = 0;

  created() {
    var toolbarButtons: ToolbarItem[] = [
      {
        callback: () => this.refresh(),
        icon: "mdi-refresh",
        tooltipText: "Refresh overview",
      },
    ];

    this.$emit("PageInfoReceived", "Payment Reminder Settings", toolbarButtons);

    this.dataApi.getCustomers(true).then((result) => {
      this.customers = result.data;
    });

    this.loadData();
  }

  refresh() {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;

    this.paymentReminderSettingsApi
      .getReminderSettings()
      .then((response) => {
        this.items = response.data;
        this.isLoading = false;
      })
      .catch((errors) => {
        this.isLoading = false;
      });
  }

  addNewItem() {
    this.disableNewItemButton = true;
    this.items.unshift({
      id: 0,
      customerId: 0,
      daysUntilFirstReminder: 0,
      daysUntilSecondReminder: 0,
      daysUntilThirdReminder: 0,
      interestPercentage: 0,
      reminderByPostCosts: 0,
    } as ReminderSettingsViewModel);
  }

  async saveItem(item: ReminderSettingsViewModel) {
    this.isLoading = true;
    const isNewItem = this.isNewItem(item);
    const postData = JSON.parse(JSON.stringify(item));

    this.paymentReminderSettingsApi
      .saveReminderSetting(postData as ReminderSettingsViewModel)
      .then((result) => {
        if (!result?.data) {
          return;
        }

        if (isNewItem) {
          this.disableNewItemButton = false;
        }
      })
      .catch((error) => {
        this.$emit("errorOccured", [error.message]);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  deleteItem(item: any) {
    this.isLoading = true;
    const index = this.items.findIndex((rs) => rs.id === item.id);

    if (this.isNewItem(item)) {
      this.items.splice(index, 1);
      this.isLoading = false;
      this.disableNewItemButton = false;
      return;
    }

    this.paymentReminderSettingsApi
      .deleteReminderSetting(item.id)
      .then((c) => {
        this.items.splice(index, 1);
      })
      .catch(() => {})
      .finally(() => {
        this.loadData();
      });
  }

  isNewItem(item: ReminderSettingsViewModel): Boolean {
    return item?.id === 0;
  }

  getCustomerName(item: ReminderSettingsViewModel) {
    var customer = this.customers.find((c) => c.id === item.customerId);
    if (!customer) {
      return "";
    }

    return customer.shortName;
  }
}
