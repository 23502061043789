<template>
  <Fragment>
    <v-card>
      <v-card-text>
        <div class="container container--fluid">
          <div class="row">
            <div class="col col-12">
              <h1>Document types</h1>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                fixed-header
                dense
              >
                <template #item.showInCourierInvoiceDocuments="{ item }">
                  <v-icon
                    :color="
                      item.showInCourierInvoiceDocuments ? 'success' : 'error'
                    "
                    >{{
                      item.showInCourierInvoiceDocuments
                        ? "mdi-check"
                        : "mdi-close"
                    }}</v-icon
                  >
                </template>
                <template #item.showInCourierInvoices="{ item }">
                  <v-icon
                    :color="item.showInCourierInvoices ? 'success' : 'error'"
                    >{{
                      item.showInCourierInvoices ? "mdi-check" : "mdi-close"
                    }}</v-icon
                  >
                </template>
                <template #item.showInCustoms="{ item }">
                  <v-icon :color="item.showInCustoms ? 'success' : 'error'">{{
                    item.showInCustoms ? "mdi-check" : "mdi-close"
                  }}</v-icon>
                </template>
                <template #item.showInCustomsDocuments="{ item }">
                  <v-icon
                    :color="item.showInCustomsDocuments ? 'success' : 'error'"
                    >{{
                      item.showInCustomsDocuments ? "mdi-check" : "mdi-close"
                    }}</v-icon
                  >
                </template>
                <template #item.actions="{ item }"
                  ><v-btn icon @click="editDocumentType(item)">
                    <v-icon>mdi-pencil</v-icon></v-btn
                  >
                  <v-btn icon @click="setDoctypeToDelete(item)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </template>
                <template #footer.prepend>
                  <v-btn @click="editDocumentType()">
                    <v-icon>mdi-plus</v-icon> ADD
                  </v-btn>
                </template>
              </v-data-table>
            </div>
          </div>
          <NewDocumentTypeDialog
            :open.sync="openDialog"
            :detail.sync="dialogModel"
            :loading.sync="saving"
            @save="saveDocumentType"
          ></NewDocumentTypeDialog>
          <ConfirmDialog
            v-model="confirmDelete"
            :is-loading="saving"
            title="Confirm delete"
            @confirm="deleteDocumentType"
          >
            Are you sure you want to delete "{{ docNameToDelete }}"?
          </ConfirmDialog>
        </div>
      </v-card-text></v-card
    >
  </Fragment>
</template>
<script setup lang="ts">
import NewDocumentTypeDialog from "./dialogs/NewDocumentTypeDialog.vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue.html";
import { computed, onMounted, ref } from "vue";
import { DataTableHeader } from "vuetify";
import { DocumentTypesApi, DocumentTypeModel } from "@/openapi";
const api = new DocumentTypesApi(undefined, "");

const headers = ref<DataTableHeader[]>([
  {
    value: "name",
    text: "Name",
  },
  {
    value: "showInCustoms",
    text: "Attachment can be selected for customs invoice",
  },
  {
    value: "showInCourierInvoices",
    text: "Attachment can be selected for courier invoice",
  },
  {
    value: "showInCustomsDocuments",
    text: "Show in Customs",
  },
  {
    value: "showInCourierInvoiceDocuments",
    text: "Show in courier invoices",
  },
  {
    value: "actions",
    text: "",
  },
]);
const loading = ref(false);
const items = ref<DocumentTypeModel[]>([]);
const openDialog = ref(false);
const saving = ref(false);
const dialogModel = ref<DocumentTypeModel>();
const confirmDelete = ref(false);
const docToDelete = ref(0);
const docNameToDelete = ref("");
const defaultDialogModel = {
  id: null,
  name: null,
  showInCustoms: false,
  showInCourierInvoices: false,
  showInCustomsDocuments: false,
  showInCourierInvoiceDocuments: false,
} as DocumentTypeModel;

const editDocumentType = (docType?: DocumentTypeModel) => {
  dialogModel.value = docType ? { ...docType } : { ...defaultDialogModel };
  openDialog.value = true;
};

const getDocumentTypes = async () => {
  loading.value = true;
  const response = await api.getDocumentTypes();
  items.value = response.data;
  loading.value = false;
};

const saveDocumentType = async (docType: DocumentTypeModel) => {
  saving.value = true;
  var response = await api.saveDocumentType(docType);
  saving.value = false;
  openDialog.value = false;
  getDocumentTypes();
};

const setDoctypeToDelete = (docType?: DocumentTypeModel) => {
  if (docType?.id) {
    docNameToDelete.value = docType?.name ?? "";
    docToDelete.value = docType?.id!;
    confirmDelete.value = true;
  } else {
    docToDelete.value = 0;
    confirmDelete.value = false;
  }
};

const deleteDocumentType = async () => {
  saving.value = true;
  var response = await api.deleteDocumentType(docToDelete.value);
  setDoctypeToDelete(0);
  saving.value = false;
  openDialog.value = false;
  getDocumentTypes();
};

onMounted(() => {
  getDocumentTypes();
});
</script>

<style lang="scss"></style>
