import ToolbarMixin from "@/mixins/ToolbarMixin";
import { Component, Mixins, Watch } from "vue-property-decorator";

import {
  CustomsDiscrepanciesApi,
  IncompleteMultipieceShipment,
  IncompleteMultipieceShipmentPagedResult,
} from "../openapi";

const api = new CustomsDiscrepanciesApi(undefined, "");

@Component({})
export default class CustomsDiscrepanciesIncomplete extends Mixins(
  ToolbarMixin,
) {
  isLoading = false;

  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [25, 50, 100],
    disablePagination: false,
  };

  items: IncompleteMultipieceShipment[] = [];
  options: any = {
    page: 1,
    itemsPerPage: 25,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };
  totalAmountOfItems = 0;

  headers = [
    {
      text: "HAWB",
      value: "hawbNumber",
      align: "center",
      sortable: false,
    },
    {
      text: "parcelNumber",
      value: "parcelNumber",
      align: "center",
      sortable: false,
    },
    {
      text: "",
      value: "",
      align: "end",
      sortable: false,
    },
  ];

  @Watch("options")
  onIncompleteOptionsChanged(newVal: any, oldVal: any) {
    this.getIncompleteMultipieceShipments(this.options.page);
  }

  async getIncompleteMultipieceShipments(page: number) {
    this.isLoading = true;
    this.items = [];
    try {
      const response = await api.getIncompleteMultipieceShipments(
        page,
        this.options.itemsPerPage,
      );
      const data: IncompleteMultipieceShipmentPagedResult = response.data;
      this.items = data.items ?? [];
      this.totalAmountOfItems = data.totalAmountOfItems ?? 0;
    } catch {
      this.displayError(
        "Something went wrong while retrieving the incomplete multipiece shipments",
      );
    }
    this.isLoading = false;
  }

  async reloadIncompleteMultiepieceShipments() {
    this.options.page == 1
      ? await this.getIncompleteMultipieceShipments(this.options.page)
      : (this.options.page = 1);
  }

  displayError(errorMessage: string) {
    this.$emit("errorOccured", errorMessage);
  }
}
