import { Component, Vue, Watch } from "vue-property-decorator";
import { ClaimViewModel, CustomsClaimApi } from "@/openapi";
import { ToolbarItem } from "../models/ToolbarItem";
import ClaimDetailView from "../components/ClaimDetailView.vue.html";

const customsClaimApi = new CustomsClaimApi(undefined, "");

@Component({
  components: {
    ClaimDetailView,
  },
})
export default class AddClaim extends Vue {
  inputHawbOrInvoice = "";
  chosenCategory = "";
  isLoading: Boolean = false;
  claim: ClaimViewModel | null = null;
  claimCategories = ["Category 1", "Category 2", "Category 3", "Category 4"];

  claimNotFound: Boolean = false;
  addClaimStep: number = 1;
  claimRegistered: Boolean = false;

  created() {
    var toolbarButtons: ToolbarItem[] = [
      {
        callback: () => this.refresh(),
        icon: "mdi-refresh",
        tooltipText: "Refresh overview",
      },
    ];

    this.$emit("PageInfoReceived", "Register an invoice claim", toolbarButtons);
  }

  refresh() {}

  onHawbInvoiceEntered(newVal: string, oldVal: string) {
    if (this.inputHawbOrInvoice === "") {
      this.claim = null;
      return;
    }

    this.loadItem();
  }

  loadItem() {
    this.isLoading = true;
    this.claimNotFound = false;
    this.claim = null;

    customsClaimApi
      .getInvoiceData(this.inputHawbOrInvoice)
      .then((response) => {
        this.claim = response.data;
        if (this.claim == null) {
          this.claimNotFound = true;
        } else {
          this.addClaimStep = 2;
          this.claimRegistered = false;
        }
        this.isLoading = false;
      })
      .catch((error) => {
        this.isLoading = false;
      });
  }

  addClaim() {
    this.isLoading = true;
    this.claimRegistered = true;

    customsClaimApi
      .addClaim(this.claim!.invoiceId, this.chosenCategory)
      .then((result) => {
        this.claimRegistered = true;
      })
      .catch((error) => {
        this.$emit("errorOccured", [error.message]);
        this.claimRegistered = false;
      })
      .finally(() => {
        this.isLoading = false;
        this.inputHawbOrInvoice = "";
      });
  }
}
