import { render, staticRenderFns } from "./Currencies.vue?vue&type=template&id=59539489&scoped=true"
import script from "./Currencies.vue?vue&type=script&setup=true&lang=ts"
export * from "./Currencies.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Currencies.vue?vue&type=style&index=0&id=59539489&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59539489",
  null
  
)

export default component.exports