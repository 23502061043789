import CountriesMixin from "@/mixins/CountriesMixin";
import CustomsFlowStepsMixin from "@/mixins/CustomsFlowStepsMixin";
import ToolbarMixin from "@/mixins/ToolbarMixin";
import {
  CustomsShipmentItemViewModel,
  CustomsTransitApi,
  CustomsShipmentDetailViewModel,
  CustomsExportApi,
} from "@/openapi";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import CustomsExportShipmentDetailViewNew from "./CustomsExportShipmentDetailViewNew.vue.html";
import ShipmentStatusFlow from "./ShipmentStatusFlow.vue.html";
import ShipmentPaperwork from "./ShipmentPaperwork.vue.html";
import FilterParcels from "./FilterParcels.vue.html";

const customsTransitApi = new CustomsTransitApi(undefined, "");
const customsExportApi = new CustomsExportApi(undefined, "");

@Component({
  components: {
    CustomsExportShipmentDetailViewNew,
    ShipmentStatusFlow,
    ShipmentPaperwork,
    FilterParcels,
  },
})
export default class CustomsExportTransitHistory extends mixins(
  CustomsFlowStepsMixin,
  CountriesMixin,
  ToolbarMixin,
) {
  loading = false;

  options: DataOptions = {
    page: 1,
    itemsPerPage: 5,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  };

  footerOptions: any = {
    showFirstLastPage: true,
    itemsPerPageOptions: [1, 5, 25, 50, 100],
    disablePagination: false,
  };

  shipmentStatusFilter = [
    "Hold",
    "Return",
    "Customs inspection",
    "Loading confirmed",
    "Waiting for customs release",
    "Customs EXIT released",
  ];

  filter: any = {
    shipmentStatusDescription: "",
    parcels: [],
    transitMrn: null,
    shipperCountry: null,
    consigneeCountry: null,
  };

  showDatepicker = false;
  dates: string[] | null = [];

  items: CustomsShipmentItemViewModel[] = [];
  currentShipmentDetail: CustomsShipmentDetailViewModel | null = null;
  totalAmountOfItems = 0;

  expanded: CustomsShipmentItemViewModel[] = [];

  headers: DataTableHeader[] = [
    {
      text: "Step",
      value: "exportFlowStatus",
      align: "center",
    },
    {
      text: "Shipment status",
      value: "shipmentStatusDescription",
    },
    {
      text: "HAWB/Parcel ID",
      value: "hawb",
      sortable: false,
      width: "15em",
    },
    {
      text: "Transit MRN ",
      value: "transitMrn",
      width: "18em",
    },
    {
      text: "Anzahl ",
      value: "pieces",
      sortable: false,
      align: "end",
    },
    {
      text: "Gewicht ",
      value: "grossWeight",
      sortable: false,
      align: "end",
    },
    { text: "EORI", value: "eoriNumber", sortable: false },
    {
      text: "Versender",
      value: "shipper",
      sortable: false,
      width: "15em",
    },
    {
      text: "Empfänger",
      value: "consignee",
      sortable: false,
      width: "15em",
    },
    {
      text: "Warenwert",
      value: "value",
      sortable: false,
      align: "end",
    },
    { text: "Währung", value: "valueCurrency", sortable: false },
    {
      text: "Warenbeschreibung / tarifnummer",
      value: "articles",
      sortable: false,
      width: "25em",
    },
  ];

  created() {
    this.addRefreshToolbarOption(this.reloadShipments);
  }

  @Watch("options")
  optionsWatcher(newValue: DataOptions, oldValue: DataOptions) {
    this.loadShipments();
  }

  @Watch("expanded")
  expandedWatcher(
    newValue: CustomsShipmentItemViewModel[],
    oldValue: CustomsShipmentItemViewModel[],
  ) {
    if (newValue.length === 0) {
      this.currentShipmentDetail = null;
    } else {
      this.currentShipmentDetail = null;
      this.loading = true;
      customsExportApi
        .getCustomsShipmentDetail(newValue[0].id!)
        .then((response) => (this.currentShipmentDetail = response.data))
        .catch((error) =>
          this.displayError(
            "Something went wrong while retrieving the detail data.",
          ),
        )
        .finally(() => (this.loading = false));
    }
  }

  async loadShipments(page: number = this.options.page) {
    this.loading = true;
    try {
      let fromDate;
      let tillDate;

      if (this.dates?.length === 2) {
        fromDate = this.dates[0];
        tillDate = this.dates[1];
      }

      const response = await customsTransitApi.getHistoryShipments({
        shipmentStatusDescription:
          this.filter.shipmentStatusDescription ?? undefined,
        parcels: this.filter.parcels,
        transitMrn: this.filter.transitMrn ?? undefined,
        shipperCountry: this.filter.shipperCountry ?? undefined,
        consigneeCountry: this.filter.consigneeCountry ?? undefined,
        fromDate,
        tillDate,
        page,
        itemsPerPage: this.options.itemsPerPage,
      });
      this.items = response.data.items ?? [];
      this.options.page = response.data.page!;
      this.options.itemsPerPage = response.data.itemsPerPage!;
      this.totalAmountOfItems = response.data.totalAmountOfItems!;
    } catch {
      this.displayError("Something went wrong while retrieve the shipments");
    }

    this.loading = false;
  }

  async reloadShipments() {
    this.options.page == 1
      ? await this.loadShipments(this.options.page)
      : (this.options.page = 1);
  }

  onFilterSelected() {
    this.reloadShipments();
  }

  displayError(errorMessage: string) {
    this.$emit("errorOccured", errorMessage);
  }

  get dateDisplayText() {
    return this.dates?.join(" - ");
  }
}
