<template>
  <div :class="classRoot" @dragenter.prevent="setDragover(true)">
    <div
      class="dragover-container"
      @drop.prevent="onDrop($event)"
      @dragover.prevent="setDragover(true)"
      @dragenter.prevent="setDragover(true)"
      @dragleave.prevent="setDragover(false)"
    >
      <v-icon>mdi-upload</v-icon>
    </div>
    <v-row>
      <v-col cols="12" class="pa-3">
        <v-card class="pa-3">
          <v-toolbar class="elevation-0" dense>
            <v-card-title>Documents</v-card-title>
            <v-spacer></v-spacer>
            <input
              type="file"
              :key="uploadCounter"
              @change="onFileChanged($event)"
              multiple
              capture
              ref="uploadField"
              class="hidden-field"
            />
            <v-btn icon @click="uploadClick()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-simple-table class="documents-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Filename</th>
                  <th>Entry date</th>
                  <th>Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="doc in documents">
                  <td>{{ doc.fileName }}</td>
                  <td>
                    {{ moment(doc.entryDate).format("YYYY-MM-DD HH:mm:ss") }}
                  </td>
                  <td>{{ doc.typeName }}</td>
                  <td>
                    <v-btn icon @click="editDocument(doc)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon @click="setToDelete(doc)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-dialog v-model="hasFilesToUpload" persistent width="660px">
            <ShipmentDocumentsDialog
              :key="timeStampKey"
              :open-dialog.sync="hasFilesToUpload"
              :files.sync="filesToUpload"
              :saving.sync="saving"
              @close-dialog="closeDialog"
              @save="saveFiles"
            ></ShipmentDocumentsDialog>
          </v-dialog>
          <DeleteDialog
            :key="timeStampKey"
            :model-open="hasFileToDelete"
            :name="fileToDelete?.fileName"
            :deleting="deleting"
            @cancel="hasFileToDelete = false"
            @delete="deleteItem()"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import ShipmentDocumentsDialog from "./dialogs/ShipmentDocumentsDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import { ShipmentDetailDocumentsModel, ShipmentDetailApi } from "@/openapi";
import moment from "moment";
import { emitError, emitErrorWithFallback } from "@/event-bus";

interface IProps {
  documents: ShipmentDetailDocumentsModel[];
  id: number;
}

const props = defineProps<IProps>();
const api = new ShipmentDetailApi(undefined, "");
const uploadField = ref<HTMLInputElement | null | undefined>(null);
const filesToUpload = ref<ShipmentDetailDocumentsModel[]>([]);
const fileToDelete = ref<ShipmentDetailDocumentsModel | null>(null);
const saving = ref(false);
const deleting = ref(false);
const timeStampKey = ref(Date.now());
const uploadCounter = ref(0);
const dragover = ref(false);

const defaultFile: ShipmentDetailDocumentsModel = {
  id: 0,
  file: null,
  fileName: null,
  entryDate: null,
  type: null,
  typeName: null,
};

const classRoot = computed(() => ({
  "v-card__text": true,
  "shipment-detail-docs-container": true,
  dragover: dragover.value,
}));

const hasFilesToUpload = computed({
  get() {
    return filesToUpload.value.length > 0;
  },
  set() {
    filesToUpload.value = [];
  },
});

const hasFileToDelete = computed({
  get() {
    return fileToDelete.value != null;
  },
  set(newValue) {
    if (!newValue) {
      fileToDelete.value = null;
    }
  },
});

const uploadClick = () => {
  uploadField.value?.click();
};

const setDragover = (val: boolean) => {
  dragover.value = val;
};

const updateTimeStampKey = () => {
  timeStampKey.value = Date.now();
};

const editDocument = (doc: ShipmentDetailDocumentsModel) => {
  updateTimeStampKey();
  filesToUpload.value.push(doc);
};

const onDrop = ($event: DragEvent) => {
  setDragover(false);
  if ($event.dataTransfer?.files && $event.dataTransfer?.files.length > 0) {
    setFilesToUpload($event.dataTransfer?.files);
  }
};

const onFileChanged = async ($event: Event) => {
  const target = $event.target as HTMLInputElement;
  setFilesToUpload(target?.files);
};

const setFilesToUpload = (files: FileList | null | undefined) => {
  updateTimeStampKey();
  if (files && files.length > 0) {
    for (let i = 0; i < files.length; i++) {
      const doc = { ...defaultFile };
      doc.file = files[i];
      doc.fileName = files[i].name;
      const parts = files[i].name.split(".");
      doc.ext = parts[parts.length - 1];
      filesToUpload.value.push(doc);
    }
  }
  uploadCounter.value++;
};

const saveFiles = async () => {
  saving.value = true;
  try {
    for (let i = 0; i < filesToUpload.value.length; i++) {
      const item = filesToUpload.value[i];
      const isNew = item.id == 0;
      const response = await api.saveShipmentDetailDocument(
        props.id,
        item.id,
        item.fileName ?? undefined,
        item.ext ?? undefined,
        item.entryDate ?? undefined,
        item.type ?? undefined,
        item.typeName ?? undefined,
        item.file ?? undefined,
      );

      if (isNew) {
        item.id = response.data.id;
        item.fileName = response.data.fileName;
        item.entryDate = response.data.entryDate;
        item.typeName = response.data.typeName;
        item.type = response.data.type;
        item.file = null;
        props.documents.unshift(item);
      } else {
        const found = props.documents.find((d) => d.id == item.id);
        if (found) {
          found.fileName = response.data.fileName;
          found.entryDate = response.data.entryDate;
          found.typeName = response.data.typeName;
          found.type = response.data.type;
          found.file = null;
        }
      }
    }
    filesToUpload.value = [];
  } catch (e: unknown) {
    emitErrorWithFallback(e, "An error occurred while saving the file(s)");
  } finally {
    saving.value = false;
  }
  closeDialog();
};

const setToDelete = (doc: ShipmentDetailDocumentsModel) => {
  updateTimeStampKey();
  fileToDelete.value = doc;
};

const deleteItem = async () => {
  if (!fileToDelete.value) {
    return;
  }
  deleting.value = true;
  try {
    const success = await api.deleteShipmentDetailDocument(
      fileToDelete.value.id,
    );
    if (success) {
      const index = props.documents.findIndex(
        (d) => d.id == fileToDelete.value?.id,
      );
      if (index >= 0) {
        props.documents.splice(index, 1);
      }
      fileToDelete.value = null;
    } else {
      emitError("Could not delete the file");
    }
  } catch (e: unknown) {
    emitErrorWithFallback(e, "An error occurred while deleting the file");
  } finally {
    deleting.value = false;
  }
};

const closeDialog = () => {
  filesToUpload.value = [];
};
</script>

<style lang="scss" scoped>
.hidden-field {
  display: none;
}

.dragover-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: white;
  z-index: 1;
  background-color: rgba(2, 209, 2, 0.2);

  i {
    margin-top: 24px;
    font-size: 48px;
  }
}

.dragover .dragover-container {
  display: flex;
}
</style>
